.btn-group .btn + .btn-default {
  margin-left: 1px;
}
.ui-rangeSlider {
  height: 27px;
  padding-top: 30px;
}
.ui-rangeSlider-withArrows .ui-rangeSlider-container,
.ui-rangeSlider-noArrow .ui-rangeSlider-container {
  background: #eeeeee;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ui-rangeSlider-innerBar {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ui-rangeSlider-bar {
  height: 20px;
  margin: 0;
  border-radius: 2px;
  background: #03a9f4;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
  height: 20px;
  border-top: 0;
  border-bottom: 0;
}
.ui-rangeSlider-leftArrow {
  border-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ui-rangeSlider-rightArrow {
  border-right: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ui-rangeSlider-arrow {
  width: 15px;
  background: #e1e1e1;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ui-rangeSlider-arrow-inner {
  border: 0;
}
.ui-rangeSlider-arrow:hover,
.ui-rangeSlider-arrow:focus {
  color: #ffffff;
  background: #d4d4d4;
}
.ui-rangeSlider-label.middle {
  position: absolute;
  bottom: 32px;
  display: block;
  padding: 0;
  cursor: default;
  white-space: nowrap;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: inherit;
}
.ui-rangeSlider-label-value {
  padding: 5px;
  color: #ffffff;
  border-radius: 2px;
  background-color: #333333;
  font-size: 11px;
  line-height: 1;
}
.ui-rangeSlider-label-inner {
  z-index: 80;
  margin-left: -6px;
  border-top-width: 6px;
  border-top-color: #333333;
  border-right-width: 6px;
  border-left-width: 6px;
}
.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-label {
  top: -2px;
  left: 1px;
}
.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-inner {
  color: #000000;
  border-left-color: #000000;
}
.ui-rangeSlider .ui-ruler-scale1 .ui-ruler-tick-inner {
  margin-top: 15px;
  border-left-color: #000000;
}
.ui-rangeSlider .ui-ruler-scale1 .ui-ruler-tick-label {
  position: relative;
  top: -15px;
  left: -5px;
  font-size: 10px;
}
.ui-ruler-tick-label {
  font-size: 12px;
  line-height: 12px;
}
.ui-datepicker-prev.ui-state-disabled,
.ui-datepicker-next.ui-state-disabled {
  color: #cccccc;
}
.navbar-brand {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 14px;
}
.navbar-brand .logo {
  margin-right: 16px;
  width: 28px;
  height: auto;
}
.navbar-brand .logo picture,
.navbar-brand .logo img {
  width: 100%;
  height: 100%;
}
.navbar-brand > .main-logo {
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-top: -1px;
  margin-right: 13px;
  vertical-align: middle;
  background: url(../images/src/img/logo.a7e85d9fc13e1c781d883783beeacef2.svg) no-repeat;
  background-size: 28px 28px;
}
.navbar-brand.navbar-brand--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.nav.navbar-right .mega-menu {
  position: relative;
}
.navbar-nav > .dropdown-user > a > span {
  overflow: hidden;
  max-width: 300px;
  padding-top: 5px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-user .dropdown-menu {
  min-width: 180px;
}
/* иногда из-за панели фильтров показывается горизонтальный скролл */
.content-wrapper {
  overflow-x: hidden;
}
@media (max-width: 1024px) {
  .datatable-scroll th,
  .datatable-scroll td {
    white-space: normal;
  }
}
.dataTables_filter {
  float: none;
  margin-left: 0;
  max-width: 300px;
}
.dataTables_filter input {
  width: 100%;
  padding-left: 34px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dataTables_filter + .clear {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dataTables_filter > label:after {
  right: auto;
  left: 12px;
}
.dataTables_length {
  width: 160px;
  margin-bottom: 0;
}
.dataTables_length > label {
  /* float: none; */
  display: block;
}
.dataTables_length > label > span:first-child {
  float: none;
  margin: inherit;
  line-height: 36px;
}
.dataTables_length select {
  display: inline-block;
  float: right;
  max-width: 70px;
}
.datatable-scroll {
  overflow-x: hidden;
}
.dataTables_info {
  white-space: nowrap;
}
.fancytree-selected .fancytree-title {
  background-color: transparent;
}
.fancytree-treefocus .fancytree-selected .fancytree-title,
.sidebar:not(.sidebar-default) .fancytree-treefocus .fancytree-selected .fancytree-title {
  color: inherit;
  background-color: transparent;
}
.fancytree-statusnode-nodata {
  text-align: center;
  border-top: 1px solid #d8d8d8;
}
.fancytree-statusnode-nodata.fancytree-node.fancytree-match .fancytree-title {
  padding-top: 0;
  color: #60606a;
  font-weight: 700;
}
#fancybox-loading div:after {
  content: '\eb50';
}
mark,
.mark {
  padding: 0;
  color: inherit;
  background-color: inherit;
}
.fancybox-close {
  top: 0;
}
/* как для noty */
.fancybox-overlay {
  background-color: rgba(51, 51, 51, 0.6);
}
.fancybox-image {
  height: auto;
  max-height: none;
}
.dropdown-menu {
  min-width: 100px;
}
.dt-button-collection.dt-button-collection > li > a {
  margin: 0;
}
.datatable-header .left,
.datatable-footer .left {
  float: left;
}
.datatable-header .right,
.datatable-footer .right {
  float: right;
}
.fancybox-inner .content-divider > span {
  background-color: #ffffff;
}
.dataTable th {
  word-break: normal;
}
.dataTable td {
  min-width: 50px;
  max-width: 400px;
  word-break: break-all;
}
.dataTable.scrollX td {
  max-width: none;
}
.dataTable .select2-container {
  min-width: 100%;
}
.dataTable.small-paddings > thead > tr > th,
.dataTable.small-paddings > tbody > tr > th,
.dataTable.small-paddings > tfoot > tr > th,
.dataTable.small-paddings > thead > tr > td,
.dataTable.small-paddings > tbody > tr > td,
.dataTable.small-paddings > tfoot > tr > td {
  padding: 12px 10px;
}
.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  padding-right: 30px;
}
.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
  right: 10px;
}
.dataTable thead .sorting:last-of-type,
.dataTable thead .sorting_asc:last-of-type,
.dataTable thead .sorting_desc:last-of-type,
.dataTable thead .sorting_asc_disabled:last-of-type,
.dataTable thead .sorting_desc_disabled:last-of-type,
.dataTable thead .sorting:first-of-type,
.dataTable thead .sorting_asc:first-of-type,
.dataTable thead .sorting_desc:first-of-type,
.dataTable thead .sorting_asc_disabled:first-of-type,
.dataTable thead .sorting_desc_disabled:first-of-type {
  padding-right: 40px !important;
}
.dataTable thead .sorting:last-of-type:before,
.dataTable thead .sorting:last-of-type:after,
.dataTable thead .sorting_asc:last-of-type:after,
.dataTable thead .sorting_desc:last-of-type:after,
.dataTable thead .sorting_asc_disabled:last-of-type:after,
.dataTable thead .sorting_desc_disabled:last-of-type:after,
.dataTable thead .sorting:first-of-type:before,
.dataTable thead .sorting:first-of-type:after,
.dataTable thead .sorting_asc:first-of-type:after,
.dataTable thead .sorting_desc:first-of-type:after,
.dataTable thead .sorting_asc_disabled:first-of-type:after,
.dataTable thead .sorting_desc_disabled:first-of-type:after {
  right: 20px !important;
}
.table > thead > tr th[colspan]:not([colspan='1']) {
  text-align: center;
  border-bottom: none;
}
.table > thead > tr th[rowspan]:not([rowspan='1']) {
  text-align: center;
}
.panel-heading .panel-title {
  display: inline-block;
}
.panel-heading a[data-action='collapse'] {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.panel-heading a[data-action='collapse'].rotate-180 {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
.noty_type_prompt .noty_message {
  text-align: left !important;
}
.noty_text {
  display: block;
  word-wrap: break-word;
}
.noty_text b {
  display: inline-block;
}
.noty_text br + b {
  overflow-y: auto;
  width: 100%;
  max-height: 250px;
  text-align: center;
}
.dataTables_wrapper .select-info,
.dataTables_wrapper .select-item {
  display: none;
}
body {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
.tooltip {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
.popover {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
.jqsfield {
  font-family: 'Roboto', sans-serif !important;
}
.jqstooltip {
  font-family: 'Roboto', sans-serif !important;
}
svg,
svg text,
.legend-title {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important;
}
svg tspan {
  font-family: 'Segoe UI Symbol', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important;
}
@media (min-width: 1025px) {
  .timeline-left .timeline-icon {
    left: 8px;
  }
}
.timeline-icon {
  width: 32px;
  height: 32px;
}
.timeline:after,
.timeline-container:after,
.timeline-date:after,
.timeline-time:after,
.timeline-icon:after {
  background-color: #e9e9e9;
}
.timeline-icon div {
  -webkit-box-shadow: 0 0 0 2px #e9e9e9 inset;
          box-shadow: 0 0 0 2px #e9e9e9 inset;
}
.form-inline .form-group > label {
  top: 0;
}
.select2-selection--multiple .select2-selection__rendered {
  white-space: normal;
}
.dataTable tbody > tr.selected.unselectable-row,
.dataTable tbody > tr > .selected.unselectable-row {
  background-color: inherit;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  visibility: hidden;
}
.b24-widget-button-visible {
  visibility: hidden !important;
}
.editableform .form-control {
  min-width: 200px;
}
.editableform textarea.form-control {
  min-width: 250px;
}
.noty_buttons.btns-block {
  padding-top: 0 !important;
  text-align: center !important;
  border-top: none !important;
}
.editableform-loading:after {
  content: '\EB50';
  font-family: 'icomoon', sans-serif;
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
body .mfp-bg {
  opacity: 1;
  background-color: rgba(51, 51, 51, 0.6);
}
body div:not(.sm-modal) .mfp-container .mfp-close:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
body div:not(.sm-modal) .mfp-container .mfp-close {
  top: 0;
  right: -33px;
  width: auto;
  height: auto;
  padding: 8px;
  opacity: 1;
  color: #ffffff;
  border-radius: 3px;
  line-height: 16px;
}
body div:not(.sm-modal) .mfp-container .mfp-content {
  width: 70vw;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel {
  padding: 30px;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-heading {
  padding: 0;
  border: none;
  background-color: #ffffff;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-heading > .panel-title {
  margin: 10px 0;
  padding: 0;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-body {
  padding: 0;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-body .w-tabs.form-group {
  margin-bottom: 0;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-footer {
  padding: 0;
  border: none;
  background-color: #ffffff;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-footer .bottom-buttons {
  text-align: center;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-footer .bottom-buttons .pull-right {
  float: none !important;
}
body div:not(.sm-modal) .mfp-container .mfp-content > .v-white-popup > .panel > .panel-footer .bottom-buttons .btn {
  min-width: 120px;
}
.footer {
  left: 300px;
}
@media (min-width: 769px) {
  .navbar-nav {
    margin-left: 0;
  }
  .sidebar {
    height: 100%;
  }
  .sidebar-xs .footer {
    left: 76px;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    z-index: 1020;
  }
}
.help-block {
  position: absolute;
}
.form-group > .help-block,
label + .help-block {
  margin-top: -6px;
}
.enabled-option-click .select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  cursor: pointer;
  opacity: 1;
  background-color: #009688;
  border-color: #009688;
  color: #fff;
}
.dataTable .dataTables_empty {
  text-align: center !important;
}
.anychart-credits {
  display: none;
}
.anychart-toolbar {
  position: absolute;
  top: 13px;
  right: 20px;
  border: none;
  outline: none;
  background: none;
}
.anychart-toolbar > * {
  display: none;
}
.anychart-toolbar-zoomIn,
.anychart-toolbar-zoomOut,
.anychart-toolbar-collapseAll,
.anychart-toolbar-expandAll {
  position: absolute;
  display: inline-block;
}
.anychart-toolbar-zoomIn {
  right: 58px;
}
.anychart-toolbar-zoomOut {
  right: 116px;
}
.anychart-toolbar-collapseAll,
.anychart-toolbar-expandAll {
  right: 0;
}
.anychart-ui-support [fill='#cecece'] {
  fill: transparent;
}
.fancytree-checkbox-wrapper {
  cursor: pointer;
}
.fancytree-checkbox-wrapper.dt-row-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
label.fancytree-checkbox-wrapper {
  margin-bottom: 0;
}
.fancytree-checkbox [type='checkbox'] {
  display: none;
}
.checkbox-inline .fancytree-checkbox {
  margin-top: 1px;
}
.fancytree-container .fancytree-checkbox {
  margin-top: 4px;
}
.widget-dropdown-menu.dt-button-collection {
  min-width: 200px;
  margin-top: 3px;
  border: 1px solid #d7dce8;
  border-width: 0 1px 1px 1px;
  border-radius: 0;
  background-color: #edeff3;
}
.widget-dropdown-menu.dt-button-collection li a {
  padding-right: 20px;
  padding-left: 20px;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.widget-dropdown-menu.dt-button-collection li a:hover {
  background-color: #d5dae6;
}
.widget-dropdown-menu.dt-button-collection > li > label {
  padding-top: 8px;
  padding-right: 20px;
  padding-bottom: 8px;
  padding-left: 20px;
}
.widget-dropdown-menu.dt-button-collection > li > label:not(.checkbox-switchery) {
  margin: 0;
}
.widget-dropdown-menu.dt-button-collection .fancytree-checkbox {
  margin-left: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: hidden;
}
.fancybox-lock body {
  overflow-y: auto !important;
}
.fancytree-checkbox {
  margin-top: 0;
  margin-left: 0;
  border-color: #687189;
  border-radius: 2px;
}
.fancytree-checkbox:after {
  color: #5d657b;
}
.checkbox-switchery input[type='checkbox'],
.switchery-double input[type='checkbox'] {
  display: none;
}
.dt-button-collection.dropdown-menu {
  z-index: 1050;
}
.select2-selection--multiple .select2-selection__choice {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-right: 24px;
  color: #6b748b;
  background-color: #eff1f6;
}
.select2-selection--multiple .select2-selection__choice .config-item-type-icon {
  font-size: 15px;
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  margin-top: 0 !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.4);
}
.select2-selection--multiple .select2-selection__choice .fa.fa-square {
  margin-right: 8px;
}
.select2-selection--multiple .select2-selection__choice .icon-icq {
  background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg version='1.1' id='icq' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'><path fill='rgb(107,116,139)' d='M20.3,11.5C23,9.4,20.9,6,17.9,6.4c1.5-3.8-4.6-6.2-6.5-2.7C8.8,0.7,4.5,3.9,6.8,7c-4.5-1.2-6.7,4.5-2.9,5.8c-1.9,1.7-0.4,4.7,2.3,4.3c-2.3,4.2,5,7,6.4,2.7c1.9,1.9,4.9,0.3,4.6-2.3C21.2,19.4,23.9,13.3,20.3,11.5z M9.9,12.1c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7C10.7,13.7,9.9,13,9.9,12.1z M18.9,7.8c1.3,0.4,1.5,1.4,0.9,2.2c-0.3,0.5-0.9,0.8-1.5,0.9c-1.1,0.1-3.1,0.4-3.8,0.4c-0.1-0.2-0.1-0.4-0.2-0.6c0.2-0.1,1.9-1.9,3-2.7l0,0C17.9,7.6,18.6,7.7,18.9,7.8z M13.2,3.7c1.2-0.9,3.1-0.2,3.5,1.1c0.2,0.6,0,1.2-0.5,1.8l0,0c-0.7,0.9-2.1,2.4-2.8,3.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.8-0.2-2.9-0.3-4C12.4,4.7,12.6,4.1,13.2,3.7z M7.7,4.7c0.3-1,1.4-1,2.3-0.5c0.3,0.2,0.7,0.6,0.8,1.3c0.1,1,0.3,2.9,0.3,3.7c-0.2,0-0.4,0.1-0.6,0.2C10,8.8,8.8,7.2,8.1,6.4C7.6,5.9,7.5,5.3,7.7,4.7z M5.2,8.3c0.5-0.1,1.5-0.2,2.4,0.6c0.9,0.8,1.3,1.3,1.6,1.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.6,0.1-2.3,0.4-3.5,0.5C2.7,11.8,2.8,8.8,5.2,8.3z M10.6,20.3c-1.1,0.8-3,0.2-3.4-1.1c-0.2-0.6,0-1.3,0.5-1.8l0,0c0.7-0.8,1.8-2,2.6-2.8c0.2,0.1,0.4,0.2,0.7,0.3c0.1,0.9,0.3,2.6,0.4,3.7C11.4,19.3,11.1,19.9,10.6,20.3z M15.7,18.4c-0.3,0.9-1.2,1.2-1.9,0.7c-0.5-0.3-0.8-0.9-0.9-1.6c-0.1-0.9-0.2-2.1-0.3-2.8c0.2-0.1,0.3-0.1,0.5-0.2c0.6,0.6,1.6,1.6,2.2,2.3l0,0C15.7,17.3,15.9,17.9,15.7,18.4z M19.1,16.5c-0.6,0.2-1.3,0-1.9-0.5l0,0c-0.8-0.6-2.2-1.8-3-2.5c0.1-0.2,0.2-0.4,0.3-0.6c1-0.1,2.9-0.3,4-0.4c0.7-0.1,1.4,0.2,1.7,0.7C21.1,14.2,20.4,16.1,19.1,16.5z'/></svg>") no-repeat;
  background-size: 100%;
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  opacity: 0.9;
}
