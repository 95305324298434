.select-container.select-container_tags .tag {
  background-color: #455A64;
  color: #fff;
  border-radius: 3px;
  cursor: default;
  float: left;
  margin-right: 3px;
  margin-top: 3px;
  padding: 7px 12px;
}
[class*=' note-icon-']:before,
[class^='note-icon-']:before {
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  font: normal 400 normal 14px 'summernote', sans-serif;
  font-size: inherit;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.note-icon-align-center:before {
  content: '\f101';
}
.note-icon-align-indent:before {
  content: '\f102';
}
.note-icon-align-justify:before {
  content: '\f103';
}
.note-icon-align-left:before {
  content: '\f104';
}
.note-icon-align-outdent:before {
  content: '\f105';
}
.note-icon-align-right:before {
  content: '\f106';
}
.note-icon-align:before {
  content: '\f107';
}
.note-icon-arrows-alt:before {
  content: '\f108';
}
.note-icon-bold:before {
  content: '\f109';
}
.note-icon-caret:before {
  content: '\f10a';
}
.note-icon-chain-broken:before {
  content: '\f10b';
}
.note-icon-circle:before {
  content: '\f10c';
}
.note-icon-close:before {
  content: '\f10d';
}
.note-icon-code:before {
  content: '\f10e';
}
.note-icon-eraser:before {
  content: '\f10f';
}
.note-icon-font:before {
  content: '\f110';
}
.note-icon-frame:before {
  content: '\f111';
}
.note-icon-italic:before {
  content: '\f112';
}
.note-icon-link:before {
  content: '\f113';
}
.note-icon-magic:before {
  content: '\f114';
}
.note-icon-menu-check:before {
  content: '\f115';
}
.note-icon-minus:before {
  content: '\f116';
}
.note-icon-orderedlist:before {
  content: '\f117';
}
.note-icon-pencil:before {
  content: '\f118';
}
.note-icon-picture:before {
  content: '\f119';
}
.note-icon-question:before {
  content: '\f11a';
}
.note-icon-redo:before {
  content: '\f11b';
}
.note-icon-special-character:before {
  content: '\f11c';
}
.note-icon-square:before {
  content: '\f11d';
}
.note-icon-strikethrough:before {
  content: '\f11e';
}
.note-icon-subscript:before {
  content: '\f11f';
}
.note-icon-summernote:before {
  content: '\f120';
}
.note-icon-superscript:before {
  content: '\f121';
}
.note-icon-table:before {
  content: '\f122';
}
.note-icon-text-height:before {
  content: '\f123';
}
.note-icon-trash:before {
  content: '\f124';
}
.note-icon-underline:before {
  content: '\f125';
}
.note-icon-undo:before {
  content: '\f126';
}
.note-icon-unorderedlist:before {
  content: '\f127';
}
.note-icon-video:before {
  content: '\f128';
}
.note-editor {
  border: 1px solid #dddddd;
  border-radius: 3px;
}
.note-editor .note-dropzone,
.note-editor .note-dropzone.hover {
  color: #2196f3;
  border: 2px dashed #2196f3;
}
.note-editor .note-dropzone {
  position: absolute;
  z-index: 1;
  display: none;
  pointer-events: none;
  opacity: 0.95;
  background-color: #ffffff;
  filter: alpha(opacity=95);
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 25px;
  font-weight: 500;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-toolbar {
  margin: 0;
  padding: 0 20px 15px;
  border-bottom: 1px solid #dddddd;
  border-radius: 3px;
  background-color: #fcfcfc;
}
.note-editor.fullscreen {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
}
.note-editor.fullscreen .note-editable {
  background-color: #ffffff;
}
.note-editor.codeview .note-editable,
.note-editor.fullscreen .note-resizebar {
  display: none;
}
.note-editor.codeview .note-codable {
  display: block;
}
.note-editor .note-statusbar {
  background-color: #fcfcfc;
}
.note-editor .note-statusbar .note-resizebar {
  width: 100%;
  height: 8px;
  cursor: s-resize;
  border-top: 1px solid #dddddd;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #aaaaaa;
}
.note-editor .note-editable {
  overflow: auto;
  min-height: 150px;
  padding: 20px;
  outline: 0;
}
.note-editor .note-editable[contenteditable='false'] {
  background-color: #eeeeee;
}
.note-editor .note-codable {
  display: none;
  width: 100%;
  margin-bottom: 0;
  padding: 20px;
  resize: none;
  color: #ffffff;
  border: 0;
  border-radius: 0;
  background-color: #333333;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'Menlo', 'Monaco', monospace, sans-serif;
}
.note-editor .modal-title {
  font-size: 17px;
}
.note-air-editor {
  outline: 0;
}
.note-popover {
  max-width: none;
}
.note-popover .popover-content {
  margin: 0;
  padding: 0 15px 15px;
}
.note-popover .popover-content a {
  display: inline-block;
  overflow: hidden;
  max-width: 200px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.note-dialog > div,
.note-popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
  display: none;
}
.note-popover .popover-content > .btn-group:last-child {
  margin-right: 0;
}
.note-popover .arrow {
  left: 20px;
}
.note-popover .popover-content > .note-btn-group,
.note-toolbar > .note-btn-group {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 0;
}
.note-popover .popover-content > .note-btn-group .note-icon-caret:before,
.note-toolbar > .note-btn-group .note-icon-caret:before {
  width: 9px;
  margin-right: 2px;
  margin-left: 2px;
}
.note-popover .popover-content > .note-btn-group i + .note-icon-caret,
.note-toolbar > .note-btn-group i + .note-icon-caret {
  margin-right: -5px;
  margin-left: 2px;
}
.note-popover .popover-content .note-style blockquote,
.note-popover .popover-content .note-style h1,
.note-popover .popover-content .note-style h2,
.note-popover .popover-content .note-style h3,
.note-popover .popover-content .note-style h4,
.note-popover .popover-content .note-style h5,
.note-popover .popover-content .note-style h6,
.note-toolbar .note-style blockquote,
.note-toolbar .note-style h1,
.note-toolbar .note-style h2,
.note-toolbar .note-style h3,
.note-toolbar .note-style h4,
.note-toolbar .note-style h5,
.note-toolbar .note-style h6 {
  margin: 0;
}
.note-popover .popover-content .note-table,
.note-toolbar .note-table {
  min-width: 0;
}
.note-popover .popover-content .note-table.dropdown-menu,
.note-toolbar .note-table.dropdown-menu {
  padding: 10px;
}
.note-popover .popover-content .note-table .note-dimension-picker,
.note-toolbar .note-table .note-dimension-picker {
  margin-bottom: 10px;
  font-size: 18px;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC');
}
.note-popover .popover-content .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC');
}
.note-popover .popover-content .note-table .note-dimension-display,
.note-toolbar .note-table .note-dimension-display {
  text-align: center;
}
.note-popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
  min-width: 330px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 7px 12px;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin-bottom: 10px;
  font-weight: 500;
}
.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  margin-bottom: 10px;
  padding: 6px 11px;
  cursor: pointer;
  text-align: center;
  background-color: #fcfcfc;
}
.note-popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
  min-width: 290px;
  padding: 10px;
  text-align: center;
}
.note-popover .popover-content .note-para .dropdown-menu > div:first-child,
.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 10px;
}
.note-popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
  min-width: 90px;
}
.note-popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .popover-content .dropdown-menu.right:before,
.note-toolbar .dropdown-menu.right:before {
  right: 9px;
  left: auto !important;
}
.note-popover .popover-content .dropdown-menu.right:after,
.note-toolbar .dropdown-menu.right:after {
  right: 10px;
  left: auto !important;
}
.note-popover .popover-content .dropdown-menu li a.checked,
.note-toolbar .dropdown-menu li a.checked {
  background-color: #f5f5f5;
}
.note-popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 17px;
  height: 17px;
  margin: 0;
  padding: 0;
  border: 1px solid #ffffff;
}
.note-popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #333333;
}
.note-popover .popover-content > .btn-group {
  margin-top: 15px;
  margin-right: 15px;
}
.note-dialog .note-image-dialog .mote-dropzone {
  min-height: 100px;
  margin-bottom: 10px;
  text-align: center;
  color: #eeeeee;
  border: 4px dashed #eeeeee;
  font-size: 25px;
}
.note-dialog .note-help-dialog {
  opacity: 0.9;
  color: #cccccc;
  border: 0;
  background-color: #333333 !important;
  filter: alpha(opacity=90);
}
.note-dialog .note-help-dialog a {
  color: #ffffff;
}
.note-dialog .note-help-dialog .title {
  padding-bottom: 7px;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  font-size: 15px;
  font-weight: 500;
}
.note-dialog .note-help-dialog .modal-content {
  border: 1px solid #ffffff;
  border-radius: 3px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.note-dialog .note-help-dialog .modal-close {
  cursor: pointer;
  color: #999999;
}
.note-dialog .note-help-dialog .note-shortcut-layout {
  width: 100%;
}
.note-dialog .note-help-dialog .note-shortcut-layout td {
  vertical-align: top;
}
.note-dialog .note-help-dialog .note-shortcut {
  margin-top: 8px;
}
.note-dialog .note-help-dialog .note-shortcut th {
  text-align: left;
  color: #eeeeee;
}
.note-dialog .note-help-dialog .note-shortcut td:first-child {
  min-width: 110px;
  padding-right: 10px;
  text-align: right;
  color: #eeeeee;
  font-family: 'Courier New', monospace;
}
.AnyTime-x-btn:after,
.daterange-custom-display:after,
.picker__nav--next:before,
.picker__nav--prev:before,
.sp-clear-display:after,
.sp-dd:after {
  font-family: 'icomoon', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.note-group-select-from-files label {
  display: block;
}
.note-handle {
  position: relative;
}
.note-handle .note-control-selection {
  position: absolute;
  z-index: 10;
  display: none;
  border: 1px solid #333333;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: #333333;
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  border: 1px solid #333333;
}
.note-handle .note-control-selection .note-control-sizing {
  background-color: #ffffff;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: 0;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.daterangepicker table tbody td,
.daterangepicker table tbody th,
.daterangepicker th.next,
.daterangepicker th.prev {
  cursor: pointer;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  margin: 5px;
  padding: 5px;
  opacity: 0.7;
  color: #ffffff;
  border-radius: 3px;
  background-color: #333333;
  filter: alpha(opacity=70);
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/src/fonts/RobotoThin/Roboto-Thin-webfont.8eebb0afc3ac2576c7b2d81b4ae036ab.woff) format('woff'), url(../fonts/src/fonts/RobotoThin/Roboto-Thin-webfont.c7941e1cf1634bf768c837da261bccb9.ttf) format('ttf'), url(../fonts/src/fonts/RobotoThin/Roboto-Thin-webfont.282b94121b9d38f26bb0bb4a91a0e1ae.eot) format('eot');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/src/fonts/RobotoRegular/Roboto-Regular-webfont.df76c8777b7b1d1e86f9a0752b221003.woff) format('woff'), url(../fonts/src/fonts/RobotoRegular/Roboto-Regular-webfont.027362a25c2407019ab0f1ed2bbdea70.ttf) format('ttf'), url(../fonts/src/fonts/RobotoRegular/Roboto-Regular-webfont.c07061787beccf30298db1286aaf0094.eot) format('eot');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/src/fonts/RobotoMedium/Roboto-Medium-webfont.7143e2a53259a0c91fc5c2708ca7e2f3.woff) format('woff'), url(../fonts/src/fonts/RobotoMedium/Roboto-Medium-webfont.17ac3557f58450451e93be3303c758da.ttf) format('ttf'), url(../fonts/src/fonts/RobotoMedium/Roboto-Medium-webfont.be8b6c459344cb72f92836077a2ce5a3.eot) format('eot');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/src/fonts/RobotoBold/Roboto-Bold-webfont.275673ed04fa507fe22399239a58eace.woff) format('woff'), url(../fonts/src/fonts/RobotoBold/Roboto-Bold-webfont.6d6ec033e9a9e70a49e2ea89711d2d41.ttf) format('ttf'), url(../fonts/src/fonts/RobotoBold/Roboto-Bold-webfont.cb2a65ce93ad28b116306eff390e95e3.eot) format('eot');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Material Icons';
  src: url(../fonts/src/fonts/MaterialIcons/MaterialIcons.1b1fa0234abd6ff8a20841e148210c7b.woff) format('woff'), url(../fonts/src/fonts/MaterialIcons/MaterialIcons.bc7370d4ef849f07ad6d49fdf754fcaf.ttf) format('ttf'), url(../fonts/src/fonts/MaterialIcons/MaterialIcons.f9485a1de944f6f8631e95cf5fe94324.eot) format('eot');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'summernote';
  src: url(../fonts/src/js/lib/summernote/dist/font/summernote.03f45d882ff3a80b719cfc0789c5eab9.woff) format('woff'), url(../fonts/src/js/lib/summernote/dist/font/summernote.549304631a5f1ab69498e294543086e3.ttf) format('ttf'), url(../fonts/src/js/lib/summernote/dist/font/summernote.7e263200d01fb5998c401b03ef3e6433.eot) format('eot');
  font-weight: normal;
  font-style: normal;
}
.material-icons {
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.word-break_break {
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.fm-namespaces .dataTable tr td {
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
script[type='text/template'] {
  display: none !important;
}
.content-wrapper {
  position: relative;
  padding-left: 64px;
}
label,
label.radio-inline,
label.checkbox-inline {
  font-weight: 700;
}
label.switchery-double,
label * {
  font-weight: 400;
}
textarea {
  resize: none;
}
.vertical-center {
  display: table-cell;
  vertical-align: middle;
}
.form-inline .form-group.btns-block {
  position: relative;
  top: -4px;
}
.btns-block {
  text-align: center;
}
.btns-block .btn {
  min-width: 120px;
}
.btn + .btn {
  margin-left: 10px;
}
.text-bold {
  font-weight: 700;
}
.hidden-column {
  display: none;
}
.actions {
  text-align: right;
}
td.actions {
  min-width: 140px;
}
.ko-tree-container {
  position: relative;
}
.ko-tree-container .search-wrapper {
  position: absolute;
  top: -50px;
  max-width: 300px;
}
.ko-tree-container .ko-tree {
  overflow: auto;
  max-height: 300px;
}
.ko-tree-container.with-search-input .ko-tree {
  margin-top: 65px;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.time-choose-container,
.filters-container {
  overflow: hidden;
  margin: 0;
  border-top: none;
  border-right: none;
  border-left: none;
}
.time-choose-container .panel-heading,
.filters-container .panel-heading {
  padding: 11px 20px;
}
.time-choose-container.panel-collapsed .panel-heading {
  border-bottom: none;
}
.time-choose-container .panel-body {
  padding-top: 10px;
  padding-bottom: 20px;
}
.picker-wrapper {
  position: relative;
  display: inline-block;
}
.picker-wrapper.right {
  float: right;
  text-align: right;
}
.time-bounds-limit {
  width: 52px;
  cursor: pointer;
  border: 0;
}
@-moz-document url-prefix() {
  .time-bounds-limit {
    width: 54px;
  }
}
.time-bounds-limit,
.nav-panel > li > a {
  color: #1e88e5;
}
.nav-panel > li > a:hover,
.nav-panel > li > a:focus,
.time-bounds-limit:hover,
.time-bounds-limit:focus {
  color: #166dba;
}
.scale-switcher {
  position: absolute;
  left: 50%;
  margin-left: -250px;
}
.scale-switcher .title {
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.scale-switcher a {
  display: inline-block;
  min-width: 32px;
  min-height: 32px;
  padding: 1px 5px 4px;
  text-align: center;
}
.scale-switcher a.active {
  cursor: default;
  color: inherit;
  font-weight: 500;
}
.scale-switcher a.disabled {
  cursor: default;
  color: #cccccc;
}
.scale-switcher .switchers {
  display: inline-block;
}
.time-choose-container .checkbox-switchery {
  margin-left: 20px;
}
.filters-submit-row {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 20px;
}
.filters-container .panel-heading .clear-filter {
  position: absolute;
  top: 6px;
  right: 63px;
  vertical-align: middle;
}
.filters-container .panel-body {
  height: auto;
}
.filters-container .user-filters-row {
  position: absolute;
  top: 7px;
  right: 113px;
  left: 120px;
  overflow: hidden;
  height: 32px;
  vertical-align: middle;
}
.filters-container .user-filters-block {
  display: inline-block;
  overflow: hidden;
  max-width: 200px;
  margin-right: 10px;
  margin-left: 10px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filters-container .user-filters-block:last-of-type {
  margin-right: 0;
}
.filters-container .datatable-scroll {
  max-height: 280px;
}
.priority-bg-color-0,
.priority-bg-color-regular,
.priority-bg-color-ok {
  color: #000000 !important;
  background-color: #23CF80 !important;
}
.priority-bg-color--1,
.priority-bg-color-unknown,
.priority-bg-color-disabled {
  color: #000000 !important;
  background-color: #B6BBBD !important;
}
.priority-bg-color-1,
.priority-bg-color-problem1 {
  color: #ffffff !important;
  background-color: #9B2101 !important;
}
.priority-bg-color-2,
.priority-bg-color-problem2 {
  color: #000000 !important;
  background-color: #F2422A !important;
}
.priority-bg-color-3,
.priority-bg-color-problem3 {
  color: #000000 !important;
  background-color: #F28727 !important;
}
.priority-bg-color-4,
.priority-bg-color-problem4 {
  color: #000000 !important;
  background-color: #F2C527 !important;
}
.priority-color-1 {
  color: #9B2101;
}
.priority-color-2 {
  color: #F2422A;
}
.priority-color-3 {
  color: #F28727;
}
.priority-color-4 {
  color: #F2C527;
}
.bus-level {
  color: #ffffff;
  background-color: #4527a0;
}
.bus-level:hover {
  color: #808080;
  background-color: inherit;
}
.app-level {
  color: #ffffff;
  background-color: #0277bd;
}
.app-level:hover {
  color: #808080;
  background-color: inherit;
}
.sys-level {
  color: #ffffff;
  background-color: #558b2f;
}
.sys-level:hover {
  color: #808080;
  background-color: inherit;
}
.bus-level-font-only {
  color: #4527a0;
}
.app-level-font-only {
  color: #0277bd;
}
.sys-level-font-only {
  color: #558b2f;
}
.blink-opacity {
  -webkit-animation-name: blink-opacity;
          animation-name: blink-opacity;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
}
@-webkit-keyframes blink-opacity {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes blink-opacity {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
.ui-ruler-tick.important-tick .ui-ruler-tick-inner {
  border-left-width: 2px;
}
.priorities-tree li ul li {
  margin-top: 0;
  margin-bottom: 5px;
}
.priorities-limits {
  margin-top: 28px;
}
.priorities-limits .form-group {
  margin-bottom: 7px;
}
.priorities-limits input {
  display: inline-block;
  width: 60px;
  height: 26px;
  margin-right: 2px;
  padding-right: 8px;
  padding-left: 8px;
}
.priorities-limits select {
  display: inline-block;
  width: 52px;
  height: 26px;
  padding: 0;
}
@media (max-width: 1023px) {
  .priorities-limits input {
    width: 32px;
  }
  .priorities-limits select {
    width: 45px;
  }
}
@media (max-width: 1500px) {
  .full-width-less-1500 {
    width: 100%;
  }
}
.empty-avatar {
  display: inline-block;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  border-radius: 50%;
  background: url(../images/limitless/images/placeholder.59e6f5b82adbc382f56107da63873a6e.jpg) no-repeat;
  background-size: 28px 28px;
}
.picker-wrapper.right .time-choose-icon {
  margin-right: 0;
  margin-left: 3px;
}
.datatables-wrapper.as-list .datatable-header {
  padding-top: 0;
  border-bottom-width: 0;
}
.datatables-wrapper.as-list .datatable-scroll .table {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.table-as-list thead {
  display: none;
}
.table-as-list tr {
  cursor: pointer;
}
.table-as-list .selected,
.table-as-list .dataTables_empty {
  cursor: default;
}
.table-as-list > tbody > tr > td {
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.table-as-list .actions .btn {
  padding: 3px 6px;
}
[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
.sm-widget.panel.micro-panel {
  margin-bottom: 15px;
}
.sm-widget.panel.micro-panel .panel-heading {
  padding: 10px;
}
.sm-widget.panel.micro-panel .panel-body {
  padding: 10px;
}
.sm-widget.panel.micro-panel .heading-elements {
  right: 10px;
}
.table-controls .pull-left,
.table-controls .pull-right {
  line-height: 36px;
}
.color-success {
  color: #4CAF50;
}
.color-danger {
  color: #F44336;
}
.datatable-splitter {
  border-top: 1px solid #bbb;
}
.chart-tooltip-table td {
  padding: 2px;
}
.fancybox-inner {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  min-height: 450px !important;
  max-height: 60vh !important;
  padding-right: 10px;
}
a.fancybox + a.fancybox:empty {
  display: none;
}
.fancybox-tmp .fancybox-skin {
  background: transparent;
}
.hidden-until-ko {
  display: none !important;
}
.under-nav-panel .undernav-panel-title-slot {
  display: inline-block;
  max-width: 30%;
  padding-left: 20px;
  vertical-align: middle;
}
#body .bootstrap-select.btn-group .dropdown-toggle .caret {
  top: 35%;
}
#body .bs-searchbox:after {
  z-index: 99;
  top: 60%;
}
#body .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  margin-top: -7px;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-row-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
.flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flex-2 {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}
.flex-3 {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}
.flex-4 {
  -webkit-box-flex: 4;
      -ms-flex: 4;
          flex: 4;
}
.flex-5 {
  -webkit-box-flex: 5;
      -ms-flex: 5;
          flex: 5;
}
/* _cursor */
.cp {
  cursor: pointer;
}
/* _margin */
.no-m {
  margin: 0 !important;
}
.no-mt {
  margin-top: 0 !important;
}
.no-mr {
  margin-right: 0 !important;
}
.no-mb {
  margin-bottom: 0 !important;
}
.no-ml {
  margin-left: 0 !important;
}
/* _padding */
.no-p {
  padding: 0 !important;
}
.ow-h,
.ov-h {
  overflow: hidden;
}
.pointer {
  cursor: pointer;
}
.ib {
  display: inline-block;
}
.d-inline-block {
  display: inline-block;
  vertical-align: middle;
}
.i {
  font-style: italic;
}
.b {
  font-weight: 700;
}
.u {
  text-decoration: underline;
}
.ta-l {
  text-align: left;
}
.ta-c {
  text-align: center;
}
.ta-r {
  text-align: right;
}
.transparent {
  opacity: 0;
}
.visible {
  visibility: visible !important;
  opacity: 1;
}
.hidden {
  display: none;
}
.invisible {
  visibility: hidden;
  opacity: 0;
}
.w-100 {
  width: 100% !important;
}
.btn.dropdown-toggle.bs-placeholder.btn-default,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
#body .select2-results .select2-results__options .select2-results__option .fa.fa-square,
body .fa.fa-square {
  top: 0;
  margin-right: 4px;
  background: transparent;
}
#body .select2-results .select2-results__options .select2-results__option .fa.fa-square.bus,
body .fa.fa-square.bus {
  color: #4527a0;
}
#body .select2-results .select2-results__options .select2-results__option .fa.fa-square.app,
body .fa.fa-square.app {
  color: #0277bd;
}
#body .select2-results .select2-results__options .select2-results__option .fa.fa-square.sys,
body .fa.fa-square.sys {
  color: #558b2f;
}
.time-choose-icon {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 2px;
  cursor: pointer;
  background: url(../images/limitless/images/ui/datepicker_trigger.c6958914afe6ae512332691d9c893620.png) no-repeat;
  background-size: 16px 16px;
}
.subtitle {
  display: inline-block;
  margin-left: 10px;
  color: #999999;
  font-size: 13px;
}
.nav.nav-tabs > li > a {
  border-radius: 3px;
}
.w-summernote.as-input + .note-editor {
  border: none;
}
.w-summernote.as-input + .note-editor .note-toolbar {
  display: none !important;
}
.w-summernote.as-input + .note-editor .note-editing-area {
  display: block;
  width: 100%;
  height: 60px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  color: #333333;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background-color: #ffffff;
  background-image: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: 13px;
  line-height: 1.5384616;
}
.w-summernote.as-input + .note-editor .note-editing-area .note-editable {
  overflow: hidden;
  height: 100%;
  min-height: 0;
  padding: 7px 12px;
}
.w-summernote.as-input + .note-editor .note-editing-area .note-editable[contenteditable='false'] {
  background: none;
}
.w-summernote.as-input + .note-editor .note-statusbar {
  display: none;
}
.sidebar-main a[href='#'],
.navbar-brand[href='#'] {
  cursor: default;
}
.full-label-wrapper {
  position: relative;
  padding: 0 !important;
  text-align: center;
}
.full-label-wrapper .bg {
  position: absolute;
  top: 12px;
  right: 10px;
  bottom: 12px;
  left: 10px;
  display: block;
  border-radius: 2px;
  font-size: 20px;
}
.full-label-wrapper .digit {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -6px;
  font-size: 20px;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-250 {
  width: 250px;
}
.layout-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.panel + .content {
  padding-top: 0;
}
.sm-widget .panel,
.sm-widget.panel {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 -2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 -2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.06);
}
.sm-widget .panel .panel-heading,
.sm-widget.panel .panel-heading {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 46px;
  margin: 0;
  padding: 0;
  padding-right: 60px;
  padding-left: 20px;
  border-bottom: 1px solid #d7dce8;
  background-color: #edeff3;
}
.sm-widget .panel .panel-heading .panel-title,
.sm-widget.panel .panel-heading .panel-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  max-height: 22px;
  color: #687189;
  font-size: 17px;
  line-height: 17px;
  line-height: 1em;
}
.sm-widget .panel .panel-heading .panel-title > span:not(.title__inline-part),
.sm-widget.panel .panel-heading .panel-title > span:not(.title__inline-part),
.sm-widget .panel .panel-heading .panel-title > div > span:not(.title__inline-part),
.sm-widget.panel .panel-heading .panel-title > div > span:not(.title__inline-part) {
  display: block;
  overflow: hidden;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sm-widget .panel .panel-heading .panel-title > div,
.sm-widget.panel .panel-heading .panel-title > div {
  width: 100%;
}
.sm-widget .panel .panel-heading .panel-title i.material-icons,
.sm-widget.panel .panel-heading .panel-title i.material-icons {
  padding-right: 12px;
  font-size: 22px;
}
.sm-widget .panel .panel-heading .preloader,
.sm-widget.panel .panel-heading .preloader {
  position: absolute;
  top: calc(-50% + 36px);
  left: 22px;
  margin: 0;
}
.sm-widget .panel .panel-heading .preloader:before,
.sm-widget.panel .panel-heading .preloader:before {
  z-index: 5;
}
.sm-widget .panel .panel-heading .preloader:after,
.sm-widget.panel .panel-heading .preloader:after {
  position: absolute;
  z-index: -1;
  top: -8px;
  left: -8px;
  width: 30px;
  height: 30px;
  content: '';
  border-radius: 50%;
  background-color: #edeff3;
}
.sm-widget .panel .panel-body,
.sm-widget.panel .panel-body {
  padding: 0;
}
.sm-widget .panel.micro-panel .panel-body,
.sm-widget.panel.micro-panel .panel-body {
  padding: 10px;
}
.sm-widget .duration-label_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.sm-widget .duration-label_wrapper.label {
  padding: 0 4px;
  border: none;
}
.sm-widget .priority-label_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  padding: 0;
  border: none;
  border-radius: 50%;
  line-height: 1em;
}
.sm-widget .priority-label_wrapper.label {
  padding: 0;
  border: none;
}
.sm-widget .priority-label_wrapper.priority-bg-color-0,
.sm-widget .priority-label_wrapper.priority-bg-color-regular,
.sm-widget .priority-label_wrapper.priority-bg-color-ok,
.sm-widget .priority-label_wrapper.priority-bg-color--1,
.sm-widget .priority-label_wrapper.priority-bg-color-unknown,
.sm-widget .priority-label_wrapper.priority-bg-color-disabled {
  width: 40px;
  border-radius: 4px;
}
.sm-widget .priority-label_wrapper.priority-bg-color-0 .priority-label,
.sm-widget .priority-label_wrapper.priority-bg-color-regular .priority-label,
.sm-widget .priority-label_wrapper.priority-bg-color-ok .priority-label,
.sm-widget .priority-label_wrapper.priority-bg-color--1 .priority-label,
.sm-widget .priority-label_wrapper.priority-bg-color-unknown .priority-label,
.sm-widget .priority-label_wrapper.priority-bg-color-disabled .priority-label {
  font-size: 11px;
  font-weight: 700;
}
.sm-widget .priority-label_wrapper.priority-bg-color-1 .priority-label {
  color: #ffffff;
}
.sm-widget .priority-label_wrapper .priority-label {
  color: #263238;
  font-size: 13px;
  line-height: 1em;
}
.sm-widget .details-control {
  position: relative;
  min-width: auto;
}
.sm-widget .details-control.details-control_opened:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  content: '';
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: transparent transparent #d7dce8 transparent;
}
.sm-widget .details-control.details-control_opened:after {
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 0;
  height: 0;
  content: '';
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: transparent transparent #edeff3 transparent;
}
.sm-widget .details-control .details-control__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border: none;
  background: transparent;
}
.sm-widget .details-control .details-control__button:hover {
  opacity: 0.9;
}
.sm-widget .details-control .details-control__button i.material-icons {
  color: #687189;
}
.sm-widget .details-control .details-control__button.details-control__button_closed i.material-icons {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}
.sm-widget .row-child-shown:hover {
  background: transparent;
}
.sm-widget .row-child-shown > td {
  padding: 0 !important;
  border-right: 1px solid #d7dce8;
  border-left: 1px solid #d7dce8;
}
.sm-widget .row-child-shown .panel:not(.micro-panel) .panel-heading {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  padding-right: 24px !important;
}
.sm-widget .row-child-shown .panel:not(.micro-panel) .panel-heading .panel-title {
  padding-left: 0;
  color: #333333;
  font-size: 16px !important;
  font-weight: 400;
}
.sm-widget .row-child-shown .panel:not(.micro-panel) .panel-heading .preloader {
  top: 8px;
  right: 0;
  left: auto;
  background-color: transparent;
}
.sm-widget .row-child-shown .panel:not(.micro-panel) .panel-heading .preloader:after {
  content: none;
}
.sm-widget .row-child-shown .panel.main-event-info,
.sm-widget .row-child-shown .panel.incident-panel {
  margin-bottom: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.sm-widget .row-child-shown .panel.main-event-info > .panel,
.sm-widget .row-child-shown .panel.incident-panel > .panel {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.sm-widget .row-child-shown .panel.main-event-info > .panel-heading,
.sm-widget .row-child-shown .panel.incident-panel > .panel-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 30px;
  margin-bottom: 12px;
  padding: 0;
  border-bottom: none;
  background-color: transparent;
}
.sm-widget .row-child-shown .panel.main-event-info > .panel-heading h6,
.sm-widget .row-child-shown .panel.incident-panel > .panel-heading h6 {
  font-size: 17px;
}
.sm-widget .row-child-shown .panel.main-event-info > .panel-heading .heading-elements,
.sm-widget .row-child-shown .panel.incident-panel > .panel-heading .heading-elements {
  position: relative;
  top: auto;
  right: auto;
  height: auto;
  margin-top: 0;
}
.sm-widget .row-child-shown .panel.main-event-info > .panel-heading .heading-elements .icons-list,
.sm-widget .row-child-shown .panel.incident-panel > .panel-heading .heading-elements .icons-list {
  margin-top: 2px;
  margin-left: 6px;
}
.sm-widget .row-child-shown .panel.main-event-info > .panel-body,
.sm-widget .row-child-shown .panel.incident-panel > .panel-body {
  padding: 0;
}
.sm-widget .row-child-shown .panel.main-event-info > .panel-body .dataTables_wrapper .dataTable,
.sm-widget .row-child-shown .panel.incident-panel > .panel-body .dataTables_wrapper .dataTable {
  padding-right: 0;
  padding-left: 0;
  border-top: none;
}
.sm-widget .row-child-shown .datatable-splitter {
  border-top: 0;
}
.sm-widget .row-child-shown .datatable-footer {
  padding: 4px;
  color: #263238;
  font-size: 12px;
}
.sm-widget .row-child-shown .datatable-footer .dataTables_info {
  margin: 0;
  padding: 4px 0 0 0;
}
.sm-widget .row-child-shown table {
  background-color: transparent;
}
.sm-widget .row-child-shown table > thead > tr > th {
  padding: 4px 8px;
  color: #687189;
  border-bottom: 0;
  background-color: #d7dce8;
  font-weight: 600;
}
.sm-widget .row-child-shown table > tbody > tr > td {
  padding: 4px 8px;
}
.sm-widget .row-child-shown .panel.main-event-info .panel-body {
  margin-bottom: 20px;
}
.sm-widget .row-child-shown .panel.incident-panel h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 4px;
}
.sm-widget .row-child-shown .panel.incident-panel h4 span:first-child {
  font-size: 14px;
  font-weight: 600;
}
.sm-widget .row-child-shown .panel.incident-panel h4 .subtitle {
  margin-left: 12px;
  padding: 3px 12px;
  color: #687189;
  border-radius: 2px;
  background-color: #d7dce8;
}
.sm-widget .row-child-shown .panel.incident-panel .timeline {
  padding-top: 0;
}
.sm-widget .row-child-shown .panel.incident-panel .timeline h6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 0;
  margin-bottom: 20px;
}
.sm-widget .row-child-shown .panel.incident-panel .timeline h6 span:first-child {
  font-weight: 700;
}
.sm-widget .row-child-shown .panel.incident-panel .timeline h6 span:last-child {
  margin-left: 12px;
  padding: 3px 12px;
  color: #687189;
  border-radius: 2px;
  background-color: rgba(215, 220, 232, 0.4);
  font-size: 12px;
}
.sm-widget .row-child-shown .panel.incident-panel .timeline .panel {
  padding: 12px;
  border: 2px solid #d7dce8;
  border-radius: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.sm-widget .row-child-shown .panel.incident-panel .timeline .panel .panel-body {
  padding: 0;
}
@media (min-width: 1025px) {
  .sm-widget .row-child-shown .panel.incident-panel .timeline.timeline-left .timeline-row,
  .sm-widget .row-child-shown .panel.incident-panel .timeline.timeline-left .timeline-date {
    padding-left: 56px;
  }
}
.sm-widget .row-child-shown .incident-panel__autor {
  font-size: 13px;
  line-height: 13px;
}
.sm-widget .row-child-shown .incident-panel__autor label {
  font-weight: 500;
}
.sm-widget .row-child-shown .panel.micro-panel .panel-heading {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: 36px;
  padding-right: 12px;
  padding-left: 12px;
  border-top: 1px solid #ececec;
}
.sm-widget .row-child-shown .panel.micro-panel .panel-heading .preloader {
  right: 12px;
}
.sm-widget .row-child-shown .panel.micro-panel .panel-heading .panel-title {
  font-size: 15px;
  font-weight: 600;
}
.sm-widget .row-child-shown .nav-tabs li.active a {
  color: #46a2fc;
  border-color: #46a2fc;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 2px;
  border-left-width: 0;
  border-radius: 0;
}
.sm-widget .dt-buttons {
  float: none;
  margin: 0;
  vertical-align: middle;
}
.sm-widget .dataTables_filter {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
.sm-widget .datatable-footer__length {
  float: none;
  width: 180px;
  margin: 0;
}
.sm-tabs_event-additional-info .sm-tabs__content,
.sm-tabs_log .sm-tabs__content {
  padding: 32px 20px;
}
.sm-widget .sm-tabs_event-additional-info .sm-widget,
.sm-widget .sm-tabs_log .sm-widget {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #d7dce8;
}
.sm-tabs_event-additional-info .sm-modal__content,
.sm-tabs_log .sm-modal__content {
  overflow-y: auto;
  max-height: 50vh;
}
.event-main-info .simple-table tr:nth-child(2n) {
  background-color: #edeff366;
}
.event-main-info__loading {
  text-align: center;
}
.event-main-info__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.event-main-info_fmon .event-main-info__row {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.event-main-info__row:not(:only-child) {
  padding-bottom: 20px;
}
.event-main-info__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin-bottom: 20px;
  width: 180px;
}
.event-main-info__item:not(:last-child) {
  padding-right: 64px;
}
.event-main-info__item:first-child {
  width: 360px;
}
.event-main-info_fmon .event-main-info__item {
  width: 180px;
}
.event-main-info__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.event-main-info__label {
  color: #71747b;
  font-size: 13px;
  padding-bottom: 2px;
  white-space: nowrap;
}
.event-main-info__value {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #333333;
  font-size: 13px;
  font-weight: 700;
  position: relative;
  line-height: 26px;
  max-width: 100%;
  white-space: nowrap;
}
.event-main-info_fmon .event-main-info__value {
  max-width: 200px;
}
.event-main-info_fmon .event-main-info__value .label {
  margin-top: 3px;
}
.event-main-info__value > a {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-main-info__value .sm-button {
  position: absolute !important;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  min-width: unset;
  height: 32px;
}
.event-main-info__value .sm-button + a {
  padding-left: 32px;
}
.event-main-info__value .sm-button .material-icons {
  font-size: 20px !important;
}
.sm-tabs_event-additional-info .build-details-container {
  margin-top: 16px;
}
.sm-tabs_event-additional-info .build-details-container .dataTable {
  padding-left: 0;
  padding-right: 0;
}
.simple-table {
  width: 100%;
  border-bottom: 0;
}
.simple-table:not(.simple-table_without-header) {
  border: 1px solid #d7dce8;
}
.simple-table.simple-table--wide-column .simple-table__inner {
  table-layout: auto;
}
.simple-table.simple-table--wide-column .simple-table__inner td:nth-child(2) {
  width: 50%;
}
.simple-table.simple-table--wide-column .simple-table__inner td:nth-child(1),
.simple-table.simple-table--wide-column .simple-table__inner td:nth-child(3) {
  width: 25%;
}
.simple-table.simple-table_actions .column-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.simple-table.simple-table_actions .column-wrapper .sm-button {
  height: 30px;
}
.simple-table.simple-table_actions .column-wrapper .sm-button_icon {
  width: 30px;
  min-width: unset;
  padding: 0;
}
.simple-table.simple-table_actions .column-wrapper .sm-button_icon .material-icons {
  font-size: 18px;
}
.simple-table.simple-table_actions .column-wrapper .notify-wrapper {
  margin-right: 16px;
}
.simple-table.simple-table_actions .column-wrapper a {
  margin-right: 16px;
}
.simple-table.simple-table_actions .column-wrapper .incident {
  width: 100%;
}
.simple-table__header {
  padding: 8px;
  background-color: #edeff3;
  border-bottom: 1px solid #d7dce8;
  font-weight: 700;
  font-size: 13px;
  color: #333333;
}
.simple-table__inner {
  width: 100%;
  table-layout: fixed;
}
.simple-table__inner thead {
  background-color: transparent;
}
.simple-table__inner th {
  text-align-last: left;
  font-size: 13px;
  font-weight: 700 !important;
  color: #333333 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  background-color: transparent !important;
}
.simple-table_without-header .simple-table__inner th {
  padding-top: 0 !important;
}
.simple-table__inner td {
  word-break: break-word !important;
  max-width: unset !important;
}
.simple-table__inner tr:last-child td {
  border-bottom: 0 !important;
}
.simple-table__row {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@media (max-width: 1400px) {
  .simple-table__row {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
th.simple-table__column,
td.simple-table__column {
  padding: 12px 20px !important;
}
@media (max-width: 1400px) {
  th.simple-table__column,
  td.simple-table__column {
    padding-left: 12px !important;
    padding-right: 8px !important;
  }
}
.simple-table__column > a {
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.simple-table__column.simple-table__column_operation-time {
  width: 260px;
  padding-left: 0 !important;
  padding-top: 0 !important;
}
th.simple-table__column.simple-table__column_operation-time {
  padding-bottom: 12px !important;
}
@media (max-width: 1600px) {
  .simple-table__column.simple-table__column_operation-time {
    width: 160px;
  }
}
.simple-table__column.simple-table__column_operation-status {
  width: 220px;
}
@media (max-width: 1600px) {
  .simple-table__column.simple-table__column_operation-status {
    width: 84px;
  }
}
.simple-table__column.simple-table__column_operation-type {
  width: 240px;
}
@media (max-width: 1600px) {
  .simple-table__column.simple-table__column_operation-type {
    width: 84px;
  }
  .simple-table__column.simple-table__column_operation-type .action-result {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.simple-table__column.simple-table__column_rule {
  width: 230px;
}
@media (max-width: 1600px) {
  .simple-table__column.simple-table__column_rule {
    width: 200px;
  }
}
@media (max-width: 1300px) {
  .simple-table__column.simple-table__column_rule {
    width: unset;
  }
}
.simple-table__column.simple-table__column_action {
  width: 230px;
}
@media (max-width: 1600px) {
  .simple-table__column.simple-table__column_action {
    width: 200px;
  }
}
@media (max-width: 1300px) {
  .simple-table__column.simple-table__column_action {
    width: unset;
  }
}
.simple-table__column-inner_full {
  width: 100%;
}
.action-statement,
.action-result,
.action-operation-type {
  position: relative;
  display: inline-block;
  padding-left: 32px;
  margin-right: 16px;
}
@media (max-width: 1600px) {
  .action-now__inner .input-container {
    padding-top: 0 !important;
  }
  .action-now__inner:last-child .input-container_with-icon {
    margin-left: 0;
  }
}
.action-now {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media (max-width: 1600px) {
  .action-now {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.action-now .date-time-picker {
  width: 136px;
}
.action-now .sm-button {
  height: 36px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.action-now .sm-button:not(.sm-button_done) {
  width: 36px;
  min-width: 36px;
  padding: 0;
}
.action-now .sm-button + .sm-button {
  margin-right: 12px;
  margin-left: 0;
}
@media (max-width: 1600px) {
  .action-now .sm-button_done {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
  }
}
.action-statement__icon {
  position: absolute;
  left: -3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 22px;
  height: 22px;
}
@media (max-width: 1600px) {
  .action-result,
  .action-operation-type {
    padding-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1601px) {
  .action-result__icon,
  .action-operation-type__icon {
    position: absolute;
    left: -3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 22px;
    height: 22px;
  }
}
.action-statement__title {
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.action-result__icon .material-icons {
  font-size: 21px;
}
.action-operation-type__title {
  white-space: nowrap;
}
@media (max-width: 1600px) {
  .action-statement__title,
  .action-operation-type__title {
    display: none;
  }
}
.action-statement__icon .material-icons {
  color: #687189;
  font-size: 22px;
}
.action-statement__icon svg {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.action-operation-type__icon.action-operation-type__icon_notification .material-icons {
  color: #fc8a23;
}
.action-operation-type__icon.action-operation-type__icon_regIncident .material-icons,
.action-operation-type__icon.action-operation-type__icon_closeIncident .material-icons {
  color: #eb5757;
}
.action-operation-type__icon.action-operation-type__icon_autoinformer .material-icons {
  color: #009688;
}
.action-operation-type__icon.action-operation-type__icon_externalScript .material-icons {
  color: #2f80ed;
}
.action-statement__date {
  color: #000000;
}
.event-main-info__value--status .legend-square {
  margin: 0 !important;
}
.event-main-info__value--status .material-icons {
  margin: 0 8px;
  color: #989898;
  font-size: 16px;
}
.event-main-info__column_history .sm-button {
  text-align: center;
  line-height: 13px;
  margin-top: 4px;
}
.datatable-scroll {
  overflow-x: unset;
}
.con-list .datatable-scroll,
.wg-list .datatable-scroll,
.sla-list .datatable-scroll,
.sm-modal_synthTrigger .datatable-scroll,
.sm-modal_jenkins .datatable-scroll {
  overflow-y: auto;
}
.row-child-shown__info-block {
  padding: 20px;
}
.row-child-shown__info {
  display: block;
  width: 100%;
}
.row-child-shown__info:not(:last-child) {
  margin-bottom: 12px;
}
.row-child-shown__info span {
  display: block;
  width: 100%;
}
.row-child-shown__info--short span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.row-child-shown__title {
  color: #333333;
  font-size: 15px;
  font-weight: 400;
}
.row-child-shown__title:not(:first-child) {
  margin-top: 32px;
}
.row-child-shown__title ~ .row-child-shown__info {
  padding-left: 12px;
}
.sm-widget .datatable-header,
.sm-modal .datatable-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  padding: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #d7dce8;
  background-color: #ffffff;
}
.sm-widget .datatable-header > div:not(:empty),
.sm-modal .datatable-header > div:not(:empty),
.sm-widget .datatable-header > span:not(:empty),
.sm-modal .datatable-header > span:not(:empty) {
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #d7dce8;
  background-color: transparent;
}
.sm-widget .datatable-header > div:last-child,
.sm-modal .datatable-header > div:last-child,
.sm-widget .datatable-header > span:last-child,
.sm-modal .datatable-header > span:last-child {
  border-right: 0;
}
.panel > .sm-widget .datatable-header,
.panel > .sm-modal .datatable-header {
  padding: 0;
}
.sm-widget .datatable-header:empty,
.sm-modal .datatable-header:empty {
  border-bottom-width: 0;
}
.sm-widget .datatable-header:after,
.sm-modal .datatable-header:after {
  content: none;
}
.sm-widget .datatable-header .vue-portal-target div:not([class]),
.sm-modal .datatable-header .vue-portal-target div:not([class]) {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 46px;
  margin: 0;
  padding: 2px;
}
.sm-widget .datatable-header .vue-portal-target div:not([class]),
.sm-modal .datatable-header .vue-portal-target div:not([class]),
.sm-widget .datatable-header .vue-portal-target div:not([class]) > span,
.sm-modal .datatable-header .vue-portal-target div:not([class]) > span,
.sm-widget .datatable-header .vue-portal-target div:not([class]) > a,
.sm-modal .datatable-header .vue-portal-target div:not([class]) > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #687189;
}
.sm-widget .datatable-header__control,
.sm-modal .datatable-header__control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 46px;
  margin: 0;
  padding: 2px;
  color: #687189;
}
.sm-widget .datatable-header__control button.btn,
.sm-modal .datatable-header__control button.btn,
.sm-widget .datatable-header__control button.sm-button,
.sm-modal .datatable-header__control button.sm-button,
.sm-widget .datatable-header__control label.btn,
.sm-modal .datatable-header__control label.btn,
.sm-widget .datatable-header__control a.btn,
.sm-modal .datatable-header__control a.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  padding: 0;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-transition: 0.3s ease background-color, 0.3s ease color, 0.2s ease padding-top;
  transition: 0.3s ease background-color, 0.3s ease color, 0.2s ease padding-top;
  color: #687189;
  border: none;
  border-radius: 1px;
  background-color: transparent;
  font-size: 13px;
  line-height: 13px;
}
.sm-widget .datatable-header__control button.btn:hover,
.sm-modal .datatable-header__control button.btn:hover,
.sm-widget .datatable-header__control button.sm-button:hover,
.sm-modal .datatable-header__control button.sm-button:hover,
.sm-widget .datatable-header__control label.btn:hover,
.sm-modal .datatable-header__control label.btn:hover,
.sm-widget .datatable-header__control a.btn:hover,
.sm-modal .datatable-header__control a.btn:hover {
  background-color: #d7dce8;
}
.sm-widget .datatable-header__control button.btn:focus,
.sm-modal .datatable-header__control button.btn:focus,
.sm-widget .datatable-header__control button.sm-button:focus,
.sm-modal .datatable-header__control button.sm-button:focus,
.sm-widget .datatable-header__control label.btn:focus,
.sm-modal .datatable-header__control label.btn:focus,
.sm-widget .datatable-header__control a.btn:focus,
.sm-modal .datatable-header__control a.btn:focus {
  border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px #d7dce8;
  box-shadow: 0 0 0 1px #d7dce8;
}
.sm-widget .datatable-header__control button.btn span.caret,
.sm-modal .datatable-header__control button.btn span.caret,
.sm-widget .datatable-header__control button.sm-button span.caret,
.sm-modal .datatable-header__control button.sm-button span.caret,
.sm-widget .datatable-header__control label.btn span.caret,
.sm-modal .datatable-header__control label.btn span.caret,
.sm-widget .datatable-header__control a.btn span.caret,
.sm-modal .datatable-header__control a.btn span.caret {
  display: none;
}
.sm-widget .datatable-header__control button.btn:before,
.sm-modal .datatable-header__control button.btn:before,
.sm-widget .datatable-header__control button.sm-button:before,
.sm-modal .datatable-header__control button.sm-button:before,
.sm-widget .datatable-header__control label.btn:before,
.sm-modal .datatable-header__control label.btn:before,
.sm-widget .datatable-header__control a.btn:before,
.sm-modal .datatable-header__control a.btn:before {
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-right: 12px;
  content: 'add';
  font-size: 22px;
}
.sm-widget .datatable-header__control.datatable-header__control--add button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--add button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--add button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--add button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--add a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--add a.btn:before {
  content: 'add';
}
.sm-widget .datatable-header__control.datatable-header__control--edit button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--edit button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--edit button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--edit button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--edit a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--edit a.btn:before {
  content: 'edit';
}
.sm-widget .datatable-header__control.datatable-header__control--back button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--back button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--back button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--back button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--back a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--back a.btn:before {
  padding-top: 3px;
  content: 'arrow_back';
}
.sm-widget .datatable-header__control.datatable-header__control--view-settings button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--view-settings button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--view-settings button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--view-settings button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--view-settings a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--view-settings a.btn:before {
  content: 'format_line_spacing';
}
.sm-widget .datatable-header__control.datatable-header__control--download button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--download button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--download label.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--download label.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--download button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--download button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--download a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--download a.btn:before {
  padding-top: 3px;
  content: 'file_download';
}
.sm-widget .datatable-header__control.datatable-header__control--remove button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--remove button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--remove button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--remove button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--remove a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--remove a.btn:before {
  content: 'close';
}
.sm-widget .datatable-header__control.datatable-header__control--accept button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--accept button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--accept button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--accept button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--accept a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--accept a.btn:before {
  content: 'check';
}
.sm-widget .datatable-header__control.datatable-header__control--events button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--events button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--events button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--events button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--events a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--events a.btn:before {
  content: 'autorenew';
}
.sm-widget .datatable-header__control.datatable-header__control--transfer button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--transfer button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--transfer button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--transfer button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--transfer a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--transfer a.btn:before {
  content: 'low_priority';
}
.sm-widget .datatable-header__control.datatable-header__control--clone button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--clone button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--clone button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--clone button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--clone a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--clone a.btn:before {
  content: 'filter_none';
  font-size: 21px;
}
.sm-widget .datatable-header__control.datatable-header__control--link button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--link button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--link button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--link button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--link a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--link a.btn:before {
  padding-right: 0;
  content: 'link';
  -webkit-transform: rotate(45deg) translate(-6px, 6px);
          transform: rotate(45deg) translate(-6px, 6px);
  font-size: 21px;
}
.sm-widget .datatable-header__control.datatable-header__control--tag button.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--tag button.btn:before,
.sm-widget .datatable-header__control.datatable-header__control--tag button.sm-button:before,
.sm-modal .datatable-header__control.datatable-header__control--tag button.sm-button:before,
.sm-widget .datatable-header__control.datatable-header__control--tag a.btn:before,
.sm-modal .datatable-header__control.datatable-header__control--tag a.btn:before {
  content: 'local_offer';
  font-size: 21px;
}
.sm-widget .datatable-header__control.datatable-header__control_direction,
.sm-modal .datatable-header__control.datatable-header__control_direction {
  width: 216px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.sm-widget .datatable-header__control.datatable-header__control_mainConfigItem,
.sm-modal .datatable-header__control.datatable-header__control_mainConfigItem {
  width: 160px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.sm-widget .datatable-header__control.datatable-header__control_source-zabbix,
.sm-modal .datatable-header__control.datatable-header__control_source-zabbix {
  width: 175px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.sm-widget .datatable-header__control.datatable-header__control_source-zabbix-group,
.sm-modal .datatable-header__control.datatable-header__control_source-zabbix-group {
  width: 175px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.sm-widget .datatable-header__control.datatable-header__control_source-zabbix-host,
.sm-modal .datatable-header__control.datatable-header__control_source-zabbix-host {
  width: 175px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.sm-widget .datatable-header__control > span,
.sm-modal .datatable-header__control > span,
.sm-widget .datatable-header__control > a,
.sm-modal .datatable-header__control > a,
.sm-widget .datatable-header__control > .input-group,
.sm-modal .datatable-header__control > .input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #687189;
}
.sm-widget .datatable-header__control .upload-form,
.sm-modal .datatable-header__control .upload-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.sm-widget .datatable-header__control .select-container,
.sm-modal .datatable-header__control .select-container {
  height: 100%;
}
.sm-widget .datatable-header__control .select-container .multiselect,
.sm-modal .datatable-header__control .select-container .multiselect {
  height: 100%;
  cursor: pointer;
  -webkit-transition: 0.3s ease background-color;
  transition: 0.3s ease background-color;
  border-radius: 1px;
}
.sm-widget .datatable-header__control .select-container .multiselect:hover,
.sm-modal .datatable-header__control .select-container .multiselect:hover,
.sm-widget .datatable-header__control .select-container .multiselect:focus,
.sm-modal .datatable-header__control .select-container .multiselect:focus {
  background-color: #d7dce8;
}
.sm-widget .datatable-header__control .select-container .multiselect__tags,
.sm-modal .datatable-header__control .select-container .multiselect__tags {
  height: 100%;
  border: none;
  background-color: transparent;
}
.sm-widget .datatable-header__control .select-container .multiselect__single,
.sm-modal .datatable-header__control .select-container .multiselect__single {
  height: 100%;
  color: #687189;
  background-color: transparent;
}
.sm-widget .datatable-header__control .select-container .multiselect__option:after,
.sm-modal .datatable-header__control .select-container .multiselect__option:after {
  content: none;
}
.sm-widget .datatable-header__control .select-container .upload-form,
.sm-modal .datatable-header__control .select-container .upload-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.sm-widget .datatable-header__control .select-container span.select2,
.sm-modal .datatable-header__control .select-container span.select2 {
  height: 100%;
}
.sm-widget .datatable-header__control .select-container span.select2 .selection,
.sm-modal .datatable-header__control .select-container span.select2 .selection {
  height: 100%;
}
.sm-widget .datatable-header__control .select-container span.select2 .select2-selection,
.sm-modal .datatable-header__control .select-container span.select2 .select2-selection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  padding: 0;
  padding-right: 18px;
  padding-left: 18px;
  -webkit-transition: 0.3s ease background-color;
  transition: 0.3s ease background-color;
  border: none;
}
.sm-widget .datatable-header__control .select-container span.select2 .select2-selection.select2-container,
.sm-modal .datatable-header__control .select-container span.select2 .select2-selection.select2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto !important;
}
.sm-widget .datatable-header__control .select-container span.select2 .select2-selection:hover,
.sm-modal .datatable-header__control .select-container span.select2 .select2-selection:hover,
.sm-widget .datatable-header__control .select-container span.select2 .select2-selection:focus,
.sm-modal .datatable-header__control .select-container span.select2 .select2-selection:focus {
  background-color: #d7dce8;
}
.sm-widget .datatable-header__control .select-container span.select2 .select2-selection.select2-selection--single .select2-selection__arrow:after,
.sm-modal .datatable-header__control .select-container span.select2 .select2-selection.select2-selection--single .select2-selection__arrow:after {
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: -10px;
  content: 'keyboard_arrow_down';
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  font-size: 20px;
}
.sm-widget .datatable-header__control .select-container span.select2 .select2-selection.select2-selection--single .select2-selection__rendered,
.sm-modal .datatable-header__control .select-container span.select2 .select2-selection.select2-selection--single .select2-selection__rendered {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  max-width: 150px;
  padding-right: 22px;
  padding-left: 0;
}
.sm-widget .datatable-header__control .select-container span.select2 .select2-selection.select2-selection--single .select2-selection__clear,
.sm-modal .datatable-header__control .select-container span.select2 .select2-selection.select2-selection--single .select2-selection__clear {
  z-index: 9999;
  opacity: 0.9;
}
.sm-widget .datatable-header__control .select-container span.select2.select2-container--open .select2-selection--single .select2-selection__arrow:after,
.sm-modal .datatable-header__control .select-container span.select2.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.sm-widget .datatable-header__control .select-container.datatable-header__control--select,
.sm-modal .datatable-header__control .select-container.datatable-header__control--select {
  position: relative;
}
.sm-widget .datatable-header__control .select-container.datatable-header__control--select:after,
.sm-modal .datatable-header__control .select-container.datatable-header__control--select:after {
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  top: 50%;
  right: 20px;
  content: 'keyboard_arrow_down';
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
  font-size: 20px;
}
.sm-widget .datatable-header__control .select-container.datatable-header__control--select select.form-control,
.sm-modal .datatable-header__control .select-container.datatable-header__control--select select.form-control {
  position: relative;
  height: 100%;
  padding-right: 40px;
  cursor: pointer;
  -webkit-transition: 0.3s ease background-color;
  transition: 0.3s ease background-color;
  color: #687189;
  border: none;
  -webkit-appearance: none;
  /* for webkit browsers */
  -moz-appearance: none;
  appearance: none;
}
.sm-widget .datatable-header__control .select-container.datatable-header__control--select select.form-control::-ms-expand,
.sm-modal .datatable-header__control .select-container.datatable-header__control--select select.form-control::-ms-expand {
  display: none;
}
.sm-widget .datatable-header__control .select-container.datatable-header__control--select select.form-control:hover,
.sm-modal .datatable-header__control .select-container.datatable-header__control--select select.form-control:hover,
.sm-widget .datatable-header__control .select-container.datatable-header__control--select select.form-control:focus,
.sm-modal .datatable-header__control .select-container.datatable-header__control--select select.form-control:focus {
  background-color: #d7dce8;
}
.sm-widget .dataTables_filter,
.sm-modal .dataTables_filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: none;
  height: 46px;
  padding: 2px;
}
.sm-widget .dataTables_filter .input-group-btn,
.sm-modal .dataTables_filter .input-group-btn {
  position: absolute;
  right: 0;
  display: none;
  width: auto;
}
.sm-widget .dataTables_filter .input-group-btn button.btn,
.sm-modal .dataTables_filter .input-group-btn button.btn {
  padding: 4px;
  border: none;
  background: transparent;
}
.sm-widget .dataTables_filter .input-group,
.sm-modal .dataTables_filter .input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}
.sm-widget .dataTables_filter .has-feedback,
.sm-modal .dataTables_filter .has-feedback {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}
.sm-widget .dataTables_filter .has-feedback:before,
.sm-modal .dataTables_filter .has-feedback:before {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 20px;
  content: 'search';
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #687189;
  font-size: 22px;
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sm-widget .dataTables_filter .form-control-feedback,
.sm-modal .dataTables_filter .form-control-feedback {
  display: none;
}
.sm-widget .dataTables_filter input,
.sm-modal .dataTables_filter input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: 50px;
  -webkit-transition: 0.3s ease background-color;
  transition: 0.3s ease background-color;
  color: #687189;
  border: none;
  background-color: transparent;
}
.sm-widget .dataTables_filter input:hover,
.sm-modal .dataTables_filter input:hover {
  background-color: #edeff3;
}
.sm-widget .dataTables_filter input:focus,
.sm-modal .dataTables_filter input:focus {
  background-color: #e4e7ed;
}
.sm-widget .dataTables_filter input::-webkit-input-placeholder,
.sm-modal .dataTables_filter input::-webkit-input-placeholder {
  color: rgba(104, 113, 137, 0.75);
}
.sm-widget .dataTables_filter input:-ms-input-placeholder,
.sm-modal .dataTables_filter input:-ms-input-placeholder {
  color: rgba(104, 113, 137, 0.75);
}
.sm-widget .dataTables_filter input::-ms-input-placeholder,
.sm-modal .dataTables_filter input::-ms-input-placeholder {
  color: rgba(104, 113, 137, 0.75);
}
.sm-widget .dataTables_filter input::placeholder,
.sm-modal .dataTables_filter input::placeholder {
  color: rgba(104, 113, 137, 0.75);
}
.sm-widget .dataTable,
.sm-modal .dataTable {
  padding-right: 16px;
  padding-left: 16px;
}
.sm-widget .dataTable > thead > tr > th:first-child,
.sm-modal .dataTable > thead > tr > th:first-child,
.sm-widget .dataTable > tbody > tr > td:first-child,
.sm-modal .dataTable > tbody > tr > td:first-child {
  min-width: auto;
}
.sm-widget .dataTable.small-paddings > tbody > tr > td,
.sm-modal .dataTable.small-paddings > tbody > tr > td {
  padding: 5px 10px;
  color: #263238;
}
.sm-widget .dataTable.small-paddings > tbody > tr > td button,
.sm-modal .dataTable.small-paddings > tbody > tr > td button {
  color: #687189;
}
.sm-widget .dataTable.small-paddings > thead > tr > th,
.sm-modal .dataTable.small-paddings > thead > tr > th {
  border-bottom-color: #d7dce8;
  font-weight: 700;
}
.sm-widget .dataTable.small-paddings > thead .sorting:before,
.sm-modal .dataTable.small-paddings > thead .sorting:before,
.sm-widget .dataTable.small-paddings > thead .sorting:after,
.sm-modal .dataTable.small-paddings > thead .sorting:after,
.sm-widget .dataTable.small-paddings > thead .sorting_asc:after,
.sm-modal .dataTable.small-paddings > thead .sorting_asc:after,
.sm-widget .dataTable.small-paddings > thead .sorting_desc:after,
.sm-modal .dataTable.small-paddings > thead .sorting_desc:after,
.sm-widget .dataTable.small-paddings > thead .sorting_asc_disabled:after,
.sm-modal .dataTable.small-paddings > thead .sorting_asc_disabled:after,
.sm-widget .dataTable.small-paddings > thead .sorting_desc_disabled:after,
.sm-modal .dataTable.small-paddings > thead .sorting_desc_disabled:after {
  right: 0;
}
.sm-widget .dataTable th.dataTable__column_executed-actions,
.sm-modal .dataTable th.dataTable__column_executed-actions,
.sm-widget .dataTable td.dataTable__column_executed-actions,
.sm-modal .dataTable td.dataTable__column_executed-actions {
  width: 140px !important;
}
.sm-widget .dataTable tbody > tr > td,
.sm-modal .dataTable tbody > tr > td {
  word-break: normal;
}
.sm-widget .dataTable tbody > tr > td.dataTable__column_word-break_break,
.sm-modal .dataTable tbody > tr > td.dataTable__column_word-break_break {
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.sm-widget .dataTable tbody > tr > td.dataTable__column_event-tags,
.sm-modal .dataTable tbody > tr > td.dataTable__column_event-tags {
  min-width: 260px;
  max-width: 300px;
}
@media (max-width: 1600px) {
  .sm-widget .dataTable tbody > tr > td.dataTable__column_event-tags,
  .sm-modal .dataTable tbody > tr > td.dataTable__column_event-tags {
    min-width: 180px;
    max-width: 240px;
  }
}
@media (max-width: 1400px) {
  .sm-widget .dataTable tbody > tr > td.dataTable__column_event-tags,
  .sm-modal .dataTable tbody > tr > td.dataTable__column_event-tags {
    min-width: 140px;
    max-width: 220px;
  }
}
.sm-widget .dataTable tbody > tr:last-child > td,
.sm-modal .dataTable tbody > tr:last-child > td {
  border-bottom: 1px solid #dddddd;
}
.sm-widget .dataTable .dt-checkboxes-cell .fancytree-checkbox,
.sm-modal .dataTable .dt-checkboxes-cell .fancytree-checkbox {
  margin-right: 0;
}
.sm-widget .datatable-header .dropdown-menu,
.sm-modal .datatable-header .dropdown-menu,
.sm-widget .widget-dropdown-menu,
.sm-modal .widget-dropdown-menu {
  min-width: 200px;
  margin-top: 1px;
  border: 1px solid #d7dce8;
  border-width: 0 1px 1px 1px;
  border-radius: 0;
  background-color: #edeff3;
}
.sm-widget .datatable-header .dropdown-menu li a,
.sm-modal .datatable-header .dropdown-menu li a,
.sm-widget .widget-dropdown-menu li a,
.sm-modal .widget-dropdown-menu li a,
.sm-widget .datatable-header .dropdown-menu li .fancytree-checkbox-wrapper,
.sm-modal .datatable-header .dropdown-menu li .fancytree-checkbox-wrapper,
.sm-widget .widget-dropdown-menu li .fancytree-checkbox-wrapper,
.sm-modal .widget-dropdown-menu li .fancytree-checkbox-wrapper {
  padding-right: 20px;
  padding-left: 20px;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.sm-widget .datatable-header .dropdown-menu li a:hover,
.sm-modal .datatable-header .dropdown-menu li a:hover,
.sm-widget .widget-dropdown-menu li a:hover,
.sm-modal .widget-dropdown-menu li a:hover,
.sm-widget .datatable-header .dropdown-menu li .fancytree-checkbox-wrapper:hover,
.sm-modal .datatable-header .dropdown-menu li .fancytree-checkbox-wrapper:hover,
.sm-widget .widget-dropdown-menu li .fancytree-checkbox-wrapper:hover,
.sm-modal .widget-dropdown-menu li .fancytree-checkbox-wrapper:hover {
  background-color: #d5dae6;
}
.sm-widget .datatable-header .dropdown-menu > li > label,
.sm-modal .datatable-header .dropdown-menu > li > label,
.sm-widget .widget-dropdown-menu > li > label,
.sm-modal .widget-dropdown-menu > li > label {
  padding-top: 8px;
  padding-right: 20px;
  padding-bottom: 8px;
  padding-left: 20px;
}
.sm-widget .datatable-header .dropdown-menu > li > label:not(.checkbox-switchery),
.sm-modal .datatable-header .dropdown-menu > li > label:not(.checkbox-switchery),
.sm-widget .widget-dropdown-menu > li > label:not(.checkbox-switchery),
.sm-modal .widget-dropdown-menu > li > label:not(.checkbox-switchery) {
  margin: 0;
}
.sm-widget .datatable-header .dropdown-menu .fancytree-checkbox,
.sm-modal .datatable-header .dropdown-menu .fancytree-checkbox,
.sm-widget .widget-dropdown-menu .fancytree-checkbox,
.sm-modal .widget-dropdown-menu .fancytree-checkbox {
  margin-left: 0;
}
.sm-widget .widget-dropdown-menu,
.sm-modal .widget-dropdown-menu {
  margin-top: 3px !important;
  margin-left: -3px;
}
.datatable-footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 20px;
  border-top: 1px solid #d7dce8;
}
.datatable-footer:before {
  position: absolute;
  top: -2px;
  right: 0;
  left: 0;
  width: 100%;
  height: 1px;
  content: '';
  background-color: #ffffff;
}
.datatable-footer:after {
  content: none;
}
.datatable-footer .datatable-footer__length {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: auto;
  height: 60px;
  margin: 0;
}
.sm-widget .datatable-footer .datatable-footer__length {
  width: auto;
}
.datatable-footer .datatable-footer__length label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: auto;
  margin-bottom: 0;
}
.datatable-footer .datatable-footer__length span {
  padding-right: 12px;
}
.datatable-footer .datatable-footer__length select {
  overflow: hidden;
  width: auto;
  min-width: none;
  max-width: none;
  height: auto;
  height: 23px;
  padding: 0;
  border: none;
}
.datatable-footer .datatable-footer__length select:focus {
  outline: none;
}
.datatable-footer .datatable-footer__length select option {
  display: inline-block;
  padding-top: 2px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-left: 10px;
  cursor: pointer;
  color: rgba(104, 113, 137, 0.75);
  border-radius: 20px;
  background: none;
}
.datatable-footer .datatable-footer__length select option:hover {
  color: #333333;
}
.datatable-footer .datatable-footer__length select option:active,
.datatable-footer .datatable-footer__length select option:checked {
  color: #000000 !important;
  background: #ffffff;
  font-weight: 700;
}
.datatable-footer .datatable-footer__length select option:focus,
.datatable-footer .datatable-footer__length select option:focus::-ms-value {
  background: none;
}
.sm-modal .datatable-header .dropdown-menu {
  margin-top: 1px;
  margin-left: -1px;
}
.sm-modal .datatable-header .datatable-header__control--download .dropdown-menu {
  margin-top: 3px;
  margin-left: -3px;
}
.sm-modal .select2-selection--single .select2-selection__rendered {
  min-width: 110px;
}
.sm-modal .select2-selection--single .select2-selection__clear {
  position: absolute;
  top: 50%;
  right: 32px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  margin-top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.sm-widget_header-redisign .panel,
.status-by-time .panel,
.events-by-time .panel,
.sla .panel,
.sm-widget .panel,
.sm-widget_header-redisign.panel,
.status-by-time.panel,
.events-by-time.panel,
.sla.panel,
.sm-widget.panel {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 -2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.06);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 -2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.06);
}
.sm-widget_header-redisign .panel .panel-heading,
.status-by-time .panel .panel-heading,
.events-by-time .panel .panel-heading,
.sla .panel .panel-heading,
.sm-widget .panel .panel-heading,
.sm-widget_header-redisign.panel .panel-heading,
.status-by-time.panel .panel-heading,
.events-by-time.panel .panel-heading,
.sla.panel .panel-heading,
.sm-widget.panel .panel-heading {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 46px;
  margin: 0;
  padding: 0;
  padding-right: 40px;
  padding-left: 20px;
  border-bottom: 1px solid #d7dce8;
  background-color: #edeff3;
}
.sm-widget_header-redisign .panel .panel-heading .panel-title,
.status-by-time .panel .panel-heading .panel-title,
.events-by-time .panel .panel-heading .panel-title,
.sla .panel .panel-heading .panel-title,
.sm-widget .panel .panel-heading .panel-title,
.sm-widget_header-redisign.panel .panel-heading .panel-title,
.status-by-time.panel .panel-heading .panel-title,
.events-by-time.panel .panel-heading .panel-title,
.sla.panel .panel-heading .panel-title,
.sm-widget.panel .panel-heading .panel-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  max-height: 22px;
  padding-left: 36px;
  color: #687189;
  font-size: 17px;
  line-height: 17px;
  line-height: 20px;
}
.sm-widget_header-redisign .panel .panel-heading .panel-title > span,
.status-by-time .panel .panel-heading .panel-title > span,
.events-by-time .panel .panel-heading .panel-title > span,
.sla .panel .panel-heading .panel-title > span,
.sm-widget .panel .panel-heading .panel-title > span,
.sm-widget_header-redisign.panel .panel-heading .panel-title > span,
.status-by-time.panel .panel-heading .panel-title > span,
.events-by-time.panel .panel-heading .panel-title > span,
.sla.panel .panel-heading .panel-title > span,
.sm-widget.panel .panel-heading .panel-title > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sm-widget_header-redisign .panel .panel-heading .panel-title i.material-icons,
.status-by-time .panel .panel-heading .panel-title i.material-icons,
.events-by-time .panel .panel-heading .panel-title i.material-icons,
.sla .panel .panel-heading .panel-title i.material-icons,
.sm-widget .panel .panel-heading .panel-title i.material-icons,
.sm-widget_header-redisign.panel .panel-heading .panel-title i.material-icons,
.status-by-time.panel .panel-heading .panel-title i.material-icons,
.events-by-time.panel .panel-heading .panel-title i.material-icons,
.sla.panel .panel-heading .panel-title i.material-icons,
.sm-widget.panel .panel-heading .panel-title i.material-icons {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 22px;
}
.sm-widget_header-redisign .panel .panel-heading .preloader,
.status-by-time .panel .panel-heading .preloader,
.events-by-time .panel .panel-heading .preloader,
.sla .panel .panel-heading .preloader,
.sm-widget .panel .panel-heading .preloader,
.sm-widget_header-redisign.panel .panel-heading .preloader,
.status-by-time.panel .panel-heading .preloader,
.events-by-time.panel .panel-heading .preloader,
.sla.panel .panel-heading .preloader,
.sm-widget.panel .panel-heading .preloader {
  position: absolute;
  top: calc(-50% + 36px);
  left: 22px;
  margin: 0;
}
.sm-widget_header-redisign .panel .panel-heading .preloader:before,
.status-by-time .panel .panel-heading .preloader:before,
.events-by-time .panel .panel-heading .preloader:before,
.sla .panel .panel-heading .preloader:before,
.sm-widget .panel .panel-heading .preloader:before,
.sm-widget_header-redisign.panel .panel-heading .preloader:before,
.status-by-time.panel .panel-heading .preloader:before,
.events-by-time.panel .panel-heading .preloader:before,
.sla.panel .panel-heading .preloader:before,
.sm-widget.panel .panel-heading .preloader:before {
  z-index: 5;
}
.sm-widget_header-redisign .panel .panel-heading .preloader:after,
.status-by-time .panel .panel-heading .preloader:after,
.events-by-time .panel .panel-heading .preloader:after,
.sla .panel .panel-heading .preloader:after,
.sm-widget .panel .panel-heading .preloader:after,
.sm-widget_header-redisign.panel .panel-heading .preloader:after,
.status-by-time.panel .panel-heading .preloader:after,
.events-by-time.panel .panel-heading .preloader:after,
.sla.panel .panel-heading .preloader:after,
.sm-widget.panel .panel-heading .preloader:after {
  position: absolute;
  z-index: -1;
  top: -8px;
  left: -8px;
  width: 30px;
  height: 30px;
  content: '';
  border-radius: 50%;
  background-color: #edeff3;
}
/*#endregion*/
.warning-production .navbar-fixed-top,
.warning-production .sidebar-main {
  background: #5b0000;
}
.warning-production .navbar-fixed-top:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -200px;
  content: 'Осторожно, реальные данные с прода!';
  font-size: 20px;
}
.duration-select {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 32px;
  padding-top: 1px;
  padding-right: 2px;
  padding-bottom: 1px;
  border: 1px solid #d7dbdb;
  border-radius: 2px !important;
}
.duration-select input {
  padding-left: 8px;
  border: none;
}
.duration-select label.btn {
  min-width: 40px;
  margin: 0;
  padding-top: 4px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-left: 8px;
  color: #b9bfbf;
  border: none;
  border-radius: 2px;
  background-color: transparent;
}
.duration-select label.btn.active {
  color: #4b4b4b;
  background: #d7dce8;
}
.btn-group_boolean[data-toggle='buttons'] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  height: 36px;
  margin-bottom: 8px;
  padding: 1px;
  border: 1px solid #d7dce8;
  border-radius: 3px;
}
.btn-group_boolean[data-toggle='buttons'] .btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50%;
  height: 100%;
  margin-bottom: 0;
  color: rgba(44, 44, 44, 0.75);
  border: none;
  border-radius: 3px;
  background-color: #ffffff;
  font-weight: 500;
}
.btn-group_boolean[data-toggle='buttons'] .btn.active {
  color: #2c2c2c;
  background-color: #d7dce7;
}
.btn-group_boolean[data-toggle='buttons'] .btn.active:hover,
.btn-group_boolean[data-toggle='buttons'] .btn.active:active,
.btn-group_boolean[data-toggle='buttons'] .btn.active:focus,
.btn-group_boolean[data-toggle='buttons'] .btn.active.focus {
  color: #2c2c2c;
  background-color: #d7dce7;
}
.btn-group_boolean[data-toggle='buttons'] .btn:hover,
.btn-group_boolean[data-toggle='buttons'] .btn:active,
.btn-group_boolean[data-toggle='buttons'] .btn:focus,
.btn-group_boolean[data-toggle='buttons'] .btn:active:focus,
.btn-group_boolean[data-toggle='buttons'] .btn:active:hover {
  color: #2c2c2c;
  background-color: #ffffff;
}
.input-container {
  position: relative;
  display: inline-block;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding-bottom: 8px;
}
.input-container .input-wrapper {
  position: relative;
}
.input-container .input-wrapper .material-icons + .form-control {
  padding-right: 34px !important;
}
.input-container .input-wrapper button.material-icons {
  padding: 0;
  border: none;
  background-color: transparent;
}
.input-container.input-container_width_small {
  width: auto;
  min-width: 230px;
}
.input-container.input-container_can-grow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.input-container.input-container_can-grow .select2 {
  width: 100%;
}
.input-container.input-container_time input[type='time'] {
  padding: 7px 12px;
  width: 116px !important;
}
@media (max-width: 1600px) {
  .input-container.input-container_time input[type='time'] {
    width: 90px !important;
  }
}
.input-container .input-container__label-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.input-container .input-container__label {
  position: relative;
  color: #9ea1a8;
}
.input-container.input-container_required .input-container__label {
  padding-right: 8px;
}
.input-container.input-container_required .input-container__inner > .input-container__label:after {
  position: absolute;
  top: -4px;
  right: 0;
  content: '*';
  color: #ff6347;
  font-weight: 500;
}
.input-container input:not(.multiselect__input):not([type='checkbox']),
.input-container textarea,
.input-container select {
  display: block;
  width: 100%;
  border: 1px solid #d7dce8;
  border-radius: 3px;
}
.input-container input:not(.multiselect__input):not([type='checkbox']):not([type='time']),
.input-container textarea {
  padding: 7px 12px;
}
.input-container .input-container__inner {
  display: inline-block;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.input-container .input-container__inner:focus > .input-container__label,
.input-container .input-container__inner:focus-within > .input-container__label,
.input-container .input-container__inner:focus > .input-container__label-wrapper .input-container__label,
.input-container .input-container__inner:focus-within > .input-container__label-wrapper .input-container__label {
  color: #2196f3;
}
.input-container .input-container__inner:focus.input-container_required > .input-container__label:after,
.input-container .input-container__inner:focus-within.input-container_required > .input-container__label:after {
  color: #ff6347;
}
.input-container .input-container__inner:focus input:not(.multiselect__input):not([type='checkbox']),
.input-container .input-container__inner:focus-within input:not(.multiselect__input):not([type='checkbox']),
.input-container .input-container__inner:focus textarea,
.input-container .input-container__inner:focus-within textarea,
.input-container .input-container__inner:focus select,
.input-container .input-container__inner:focus-within select {
  border-color: #2196f3;
  border-radius: 3px;
}
.input-container:not(:last-child) {
  padding-right: 20px;
}
.input-container .set-triggers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 36px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-transition: 0.3s ease background-color;
  transition: 0.3s ease background-color;
  border: 1px solid #d7dce8;
  border-radius: 3px;
  line-height: 0.9;
}
.input-container .set-triggers:hover {
  background-color: #d7dce8;
}
.input-container .set-triggers .set-triggers {
  white-space: nowrap;
}
.input-container.input-container_with-icon {
  padding-right: 0;
}
.input-container.input-container_with-icon:before {
  position: absolute;
  top: 50%;
  right: 10px;
  display: inline-block;
  content: 'calendar_today';
  cursor: pointer;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  pointer-events: none;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  color: #687189;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}
.input-container_with-icon + .input-container {
  margin-left: 20px;
}
.input-container__error {
  position: absolute;
  right: 0;
  bottom: -12px;
  left: 0;
  width: calc(100% - 20px);
  height: 24px;
  padding: 4px 8px;
  text-align: center;
  color: #ff6347;
  border-radius: 2px;
  font-size: 11px;
  line-height: 11px;
}
.radio-button {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 8px;
  cursor: pointer;
}
.radio-button input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}
.radio-button input ~ span:before {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 2px #687189 inset;
          box-shadow: 0 0 0 2px #687189 inset;
}
.radio-button input:checked ~ span:before {
  -webkit-box-shadow: 0 0 0 2px #2196f3 inset;
          box-shadow: 0 0 0 2px #2196f3 inset;
}
.radio-button input:checked ~ span:after {
  display: block;
}
.radio-button span {
  padding-left: 32px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 18px;
}
.radio-button span:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  background-color: transparent;
}
.radio-button span:after {
  position: absolute;
  top: 50%;
  left: 5px;
  display: none;
  width: 10px;
  height: 10px;
  content: '';
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  background: #2196f3;
}
.radio-button:hover input ~ span:before {
  background-color: rgba(33, 150, 243, 0.16);
}
.content_is-loading {
  display: block !important;
}
.icon-icq {
  display: inline-block;
  -ms-flex-item-align: center;
      align-self: center;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg version='1.1' id='icq' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'><path fill='rgb(53,73,94)' d='M20.3,11.5C23,9.4,20.9,6,17.9,6.4c1.5-3.8-4.6-6.2-6.5-2.7C8.8,0.7,4.5,3.9,6.8,7c-4.5-1.2-6.7,4.5-2.9,5.8c-1.9,1.7-0.4,4.7,2.3,4.3c-2.3,4.2,5,7,6.4,2.7c1.9,1.9,4.9,0.3,4.6-2.3C21.2,19.4,23.9,13.3,20.3,11.5z M9.9,12.1c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7C10.7,13.7,9.9,13,9.9,12.1z M18.9,7.8c1.3,0.4,1.5,1.4,0.9,2.2c-0.3,0.5-0.9,0.8-1.5,0.9c-1.1,0.1-3.1,0.4-3.8,0.4c-0.1-0.2-0.1-0.4-0.2-0.6c0.2-0.1,1.9-1.9,3-2.7l0,0C17.9,7.6,18.6,7.7,18.9,7.8z M13.2,3.7c1.2-0.9,3.1-0.2,3.5,1.1c0.2,0.6,0,1.2-0.5,1.8l0,0c-0.7,0.9-2.1,2.4-2.8,3.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.8-0.2-2.9-0.3-4C12.4,4.7,12.6,4.1,13.2,3.7z M7.7,4.7c0.3-1,1.4-1,2.3-0.5c0.3,0.2,0.7,0.6,0.8,1.3c0.1,1,0.3,2.9,0.3,3.7c-0.2,0-0.4,0.1-0.6,0.2C10,8.8,8.8,7.2,8.1,6.4C7.6,5.9,7.5,5.3,7.7,4.7z M5.2,8.3c0.5-0.1,1.5-0.2,2.4,0.6c0.9,0.8,1.3,1.3,1.6,1.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.6,0.1-2.3,0.4-3.5,0.5C2.7,11.8,2.8,8.8,5.2,8.3z M10.6,20.3c-1.1,0.8-3,0.2-3.4-1.1c-0.2-0.6,0-1.3,0.5-1.8l0,0c0.7-0.8,1.8-2,2.6-2.8c0.2,0.1,0.4,0.2,0.7,0.3c0.1,0.9,0.3,2.6,0.4,3.7C11.4,19.3,11.1,19.9,10.6,20.3z M15.7,18.4c-0.3,0.9-1.2,1.2-1.9,0.7c-0.5-0.3-0.8-0.9-0.9-1.6c-0.1-0.9-0.2-2.1-0.3-2.8c0.2-0.1,0.3-0.1,0.5-0.2c0.6,0.6,1.6,1.6,2.2,2.3l0,0C15.7,17.3,15.9,17.9,15.7,18.4z M19.1,16.5c-0.6,0.2-1.3,0-1.9-0.5l0,0c-0.8-0.6-2.2-1.8-3-2.5c0.1-0.2,0.2-0.4,0.3-0.6c1-0.1,2.9-0.3,4-0.4c0.7-0.1,1.4,0.2,1.7,0.7C21.1,14.2,20.4,16.1,19.1,16.5z'/></svg>") no-repeat;
  background-size: 16px 16px;
}
.multiselect__option .icon-icq,
.multiselect__tag .icon-icq {
  position: absolute;
  top: 50%;
  left: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.multiselect__option .icon-icq + span,
.multiselect__tag .icon-icq + span {
  padding-left: 19px;
}
.select2-selection .icon-skype,
.select2-selection .icon-paperplane,
.select2-selection .icon-mention {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[v-cloak] {
  display: none !important;
}
#app {
  height: 100%;
}
.page-container {
  height: 100%;
}
.sm-sidebar .datatable-footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.sm-widget-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  height: 46px;
  padding: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #d7dce8;
  background-color: #ffffff;
}
.sm-widget-controls.sm-widget-controls_color-gray {
  background-color: #edeff3;
}
.sm-widget-controls.sm-widget-controls_grid_two {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.sm-widget-controls__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 46px;
}
.sm-widget-controls__column:not(:first-child) {
  border-left: 1px solid #d7dce8;
}
.sm-widget-controls__column:last-child .sm-widget-controls__item:last-child {
  border-right: 0;
}
.sm-widget-controls__item {
  height: 100%;
  padding: 2px;
  border-right: 1px solid #d7dce8;
}
.sm-widget-controls__item a,
.sm-widget-controls__item button,
.sm-widget-controls__item a.btn,
.sm-widget-controls__item button.btn,
.sm-widget-controls__item a.sm-button,
.sm-widget-controls__item button.sm-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0 20px 0 16px;
  -webkit-transition: 0.3s ease background, 0.3s ease color, 0.2s ease padding-top;
  transition: 0.3s ease background, 0.3s ease color, 0.2s ease padding-top;
  color: #687189;
  border: 0;
  border-radius: 2px;
  background-color: transparent;
  font-size: 13px;
}
.sm-widget-controls__item a:hover,
.sm-widget-controls__item button:hover,
.sm-widget-controls__item a.btn:hover,
.sm-widget-controls__item button.btn:hover,
.sm-widget-controls__item a.sm-button:hover,
.sm-widget-controls__item button.sm-button:hover {
  background-color: #edeff3;
}
.sm-widget-controls_color-gray .sm-widget-controls__item a:hover,
.sm-widget-controls_color-gray .sm-widget-controls__item button:hover,
.sm-widget-controls_color-gray .sm-widget-controls__item a.btn:hover,
.sm-widget-controls_color-gray .sm-widget-controls__item button.btn:hover,
.sm-widget-controls_color-gray .sm-widget-controls__item a.sm-button:hover,
.sm-widget-controls_color-gray .sm-widget-controls__item button.sm-button:hover {
  background-color: #e4e7ed;
}
.sm-widget-controls__item a:focus,
.sm-widget-controls__item button:focus,
.sm-widget-controls__item a.btn:focus,
.sm-widget-controls__item button.btn:focus,
.sm-widget-controls__item a.sm-button:focus,
.sm-widget-controls__item button.sm-button:focus {
  border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px #d7dce8;
          box-shadow: 0 0 0 1px #d7dce8;
}
.sm-widget-controls__item a:active,
.sm-widget-controls__item button:active,
.sm-widget-controls__item a.btn:active,
.sm-widget-controls__item button.btn:active,
.sm-widget-controls__item a.sm-button:active,
.sm-widget-controls__item button.sm-button:active {
  background-color: #dee1e9;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.sm-widget-controls_color-gray .sm-widget-controls__item a:active,
.sm-widget-controls_color-gray .sm-widget-controls__item button:active,
.sm-widget-controls_color-gray .sm-widget-controls__item a.btn:active,
.sm-widget-controls_color-gray .sm-widget-controls__item button.btn:active,
.sm-widget-controls_color-gray .sm-widget-controls__item a.sm-button:active,
.sm-widget-controls_color-gray .sm-widget-controls__item button.sm-button:active {
  background-color: #dee1e9;
}
.sm-widget-controls__item a i.material-icons,
.sm-widget-controls__item button i.material-icons,
.sm-widget-controls__item a.btn i.material-icons,
.sm-widget-controls__item button.btn i.material-icons,
.sm-widget-controls__item a.sm-button i.material-icons,
.sm-widget-controls__item button.sm-button i.material-icons {
  font-size: 22px;
}
.sm-widget-controls__item a i.material-icons + span,
.sm-widget-controls__item button i.material-icons + span,
.sm-widget-controls__item a.btn i.material-icons + span,
.sm-widget-controls__item button.btn i.material-icons + span,
.sm-widget-controls__item a.sm-button i.material-icons + span,
.sm-widget-controls__item button.sm-button i.material-icons + span {
  padding-left: 12px;
}
.sm-widget-controls__item a span,
.sm-widget-controls__item button span,
.sm-widget-controls__item a.btn span,
.sm-widget-controls__item button.btn span,
.sm-widget-controls__item a.sm-button span,
.sm-widget-controls__item button.sm-button span {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sm-widget-controls__item a span + i.material-icons,
.sm-widget-controls__item button span + i.material-icons,
.sm-widget-controls__item a.btn span + i.material-icons,
.sm-widget-controls__item button.btn span + i.material-icons,
.sm-widget-controls__item a.sm-button span + i.material-icons,
.sm-widget-controls__item button.sm-button span + i.material-icons {
  margin-left: 12px;
}
.sm-widget-controls__item a:before,
.sm-widget-controls__item button:before,
.sm-widget-controls__item a.btn:before,
.sm-widget-controls__item button.btn:before,
.sm-widget-controls__item a.sm-button:before,
.sm-widget-controls__item button.sm-button:before {
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-right: 12px;
  content: 'add';
  font-size: 22px;
}
.sm-widget-controls__item.sm-widget-controls__item_add a:before,
.sm-widget-controls__item.sm-widget-controls__item_add button:before {
  content: 'add';
}
.sm-widget-controls__item.sm-widget-controls__item_view-settings a:before,
.sm-widget-controls__item.sm-widget-controls__item_view-settings button:before {
  content: 'format_line_spacing';
}
.sm-widget-controls__item.sm-widget-controls__item_action_download a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_download button:before {
  padding-top: 3px;
  content: 'file_download';
}
.sm-widget-controls__item.sm-widget-controls__item_action_edit a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_edit button:before {
  content: 'edit';
  font-size: 20px;
}
.sm-widget-controls__item.sm-widget-controls__item_action_stop a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_stop button:before {
  content: 'stop';
  font-size: 20px;
}
.sm-widget-controls__item.sm-widget-controls__item_action_play a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_play button:before {
  content: 'play_arrow';
  font-size: 20px;
}
.sm-widget-controls__item.sm-widget-controls__item_action_open a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_open button:before {
  content: 'open_in_new';
  font-size: 20px;
}
.sm-widget-controls__item.sm-widget-controls__item_action_delete a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_delete button:before {
  content: 'delete_forever';
  font-size: 20px;
}
.sm-widget-controls__item.sm-widget-controls__item_navigation_back a:before,
.sm-widget-controls__item.sm-widget-controls__item_navigation_back button:before {
  padding-top: 3px;
  content: 'arrow_back';
  -webkit-transition: 0.3s ease margin;
  transition: 0.3s ease margin;
}
.sm-widget-controls__item.sm-widget-controls__item_navigation_back a:hover,
.sm-widget-controls__item.sm-widget-controls__item_navigation_back button:hover {
  background: -webkit-gradient(linear, left top, right top, from(#edeff3), to(rgba(237, 239, 243, 0.5)));
  background: linear-gradient(to right, #edeff3, rgba(237, 239, 243, 0.5));
}
.sm-widget-controls__item.sm-widget-controls__item_navigation_back a:hover:before,
.sm-widget-controls__item.sm-widget-controls__item_navigation_back button:hover:before {
  margin-right: 2px;
  margin-left: -2px;
}
.sm-widget-controls__item.sm-widget-controls__item_action_remove a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_cancel a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_remove button:before,
.sm-widget-controls__item.sm-widget-controls__item_action_cancel button:before {
  content: 'close';
}
.sm-widget-controls__item.sm-widget-controls__item_action_accept a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_submit a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_save a:before,
.sm-widget-controls__item.sm-widget-controls__item_action_accept button:before,
.sm-widget-controls__item.sm-widget-controls__item_action_submit button:before,
.sm-widget-controls__item.sm-widget-controls__item_action_save button:before {
  content: 'check';
}
.sm-widget-controls__item.sm-widget-controls__item_events a:before,
.sm-widget-controls__item.sm-widget-controls__item_events button:before {
  content: 'autorenew';
}
.sm-widget-controls__item.sm-widget-controls__item_transfer a:before,
.sm-widget-controls__item.sm-widget-controls__item_transfer button:before {
  content: 'low_priority';
}
.sm-widget-controls__item.sm-widget-controls__item_no-icon a:before,
.sm-widget-controls__item.sm-widget-controls__item_no-icon button:before {
  content: none;
}
.sm-widget-controls__item.sm-widget-controls__item_link a,
.sm-widget-controls__item.sm-widget-controls__item_link button {
  padding-left: 16px;
}
.sm-widget-controls__item.sm-widget-controls__item_link a:after,
.sm-widget-controls__item.sm-widget-controls__item_link button:after {
  position: absolute;
  top: 0;
  right: 0;
  content: 'arrow_upward';
  -webkit-transform: rotate(45deg) translate(-3px, -6px);
          transform: rotate(45deg) translate(-3px, -6px);
  opacity: 0.2;
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.sm-tabs__header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 46px;
  margin-bottom: 0;
  border-bottom: 1px solid #d7dce8;
  background-color: #edeff3;
}
.sm-tabs__header > li {
  border: 0;
}
.sm-tabs__header > li > a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 46px;
  padding: 0;
  padding: 0 20px 0 20px;
  text-transform: uppercase;
  border: 0;
}
.sm-tabs__header > li > a:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  content: '';
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
  background-color: #46a2fc;
}
.sm-tabs__header > li > a .preloader {
  position: absolute;
  top: calc(50% - 8px);
  right: 27px;
}
.sm-tabs__header > li > a:hover,
.sm-tabs__header > li > a:focus {
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.sm-tabs__header > li.active {
  border: 0;
}
.sm-tabs__header > li.active > a {
  color: #46a2fc;
  border: 0;
  background-color: transparent;
}
.sm-tabs__header > li.active > a:after {
  height: 2px;
}
.sm-tabs__header > li.active > a span {
  font-weight: 500;
}
.sm-tabs__header > li.active > a:hover,
.sm-tabs__header > li.active > a:focus {
  cursor: pointer;
  color: #46a2fc;
  border: none;
  background-color: transparent;
}
.sm-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 60px;
  height: 40px;
  padding: 0 20px;
  -webkit-transition: 0.3s ease background, 0.3s ease color, 0.3s ease box-shadow;
  transition: 0.3s ease background, 0.3s ease color, 0.3s ease box-shadow;
  color: #44414d;
  border: none;
  border-radius: 3px;
  background: #eff2f9;
  font-size: 13px;
}
.sm-button + .sm-button {
  margin-left: 24px;
}
.sm-button span {
  -webkit-transition: 0.3s ease transform;
  transition: 0.3s ease transform;
}
.sm-button span + i.material-icons {
  margin-left: 12px;
}
.sm-button i.material-icons {
  -webkit-transition: 0.3s ease transform;
  transition: 0.3s ease transform;
  font-size: 22px;
  line-height: 20px;
}
.sm-button i.material-icons + span {
  padding-left: 12px;
}
.sm-button:hover {
  background-color: #dce3f2;
}
.sm-button:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(220, 227, 242, 0.95), 0 0 1px 1px rgba(0, 0, 0, 0.15) inset;
          box-shadow: 0 0 0 3px rgba(220, 227, 242, 0.95), 0 0 1px 1px rgba(0, 0, 0, 0.15) inset;
}
.sm-button:active {
  background-color: #dce3f2;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15) inset;
          box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15) inset;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.sm-button.sm-button_primary {
  color: #ffffff;
  background-color: #2196f3;
}
.sm-button.sm-button_primary:hover {
  background-color: #0d8aee;
  -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2) inset;
          box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2) inset;
}
.sm-button.sm-button_primary:active {
  background-color: #0d8aee;
  -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2) inset;
          box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2) inset;
}
.sm-button.sm-button_primary:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.5), 0 -1px 1px 0 rgba(0, 0, 0, 0.2) inset;
          box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.5), 0 -1px 1px 0 rgba(0, 0, 0, 0.2) inset;
}
.sm-button.sm-button_primary i.material-icons {
  color: #ffffff;
}
.sm-button.sm-button_add-macros {
  height: auto;
  padding: 0 4px;
  min-width: unset;
  background: #f4edfc;
}
.sm-button.sm-button_add-macros .macros-icon {
  width: 22px;
  height: 22px;
  background-position: center center;
  background-image: url(../images/src/img/macros/macros.6c56fd9f683ea5fbb6a3f4997f28335c.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}
.note-editor + .sm-button.sm-button_add-macros {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.note-editor .sm-button.sm-button_add-macros {
  width: 37px;
  height: 34px;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fcfcfc;
}
.note-editor .sm-button.sm-button_add-macros:hover,
.note-editor .sm-button.sm-button_add-macros:hover:focus,
.note-editor .sm-button.sm-button_add-macros:focus {
  border: 1px solid #cccccc;
}
.sm-button.sm-button_add-macros:hover,
.sm-button.sm-button_add-macros:hover:focus {
  background: #cccccc;
}
.sm-button.sm-button_add-macros:focus {
  -webkit-box-shadow: 0px 0px 0px 1px #7d3ad0;
          box-shadow: 0px 0px 0px 1px #7d3ad0;
}
.sm-button.sm-button_add-macros .material-icons {
  color: #7d3ad0;
}
.sm-button.sm-button_full-width {
  width: 100%;
}
.sm-button.sm-button_field-style {
  height: 36px;
  margin-top: 20px;
}
.sm-button.sm-button_text {
  height: auto;
  padding: 0 2px;
  text-decoration: underline;
  color: #2196f3;
  border-radius: 1px;
  background: transparent;
}
.sm-button.sm-button_text:hover {
  background-color: rgba(220, 227, 242, 0.25);
}
.sm-button.sm-button_text:focus,
.sm-button.sm-button_text:active {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px rgba(33, 150, 243, 0.5);
          box-shadow: 0 0 0 1px rgba(33, 150, 243, 0.5);
}
.sm-button.sm-button_icon {
  position: relative;
  z-index: 1;
  padding: 0;
  background: transparent;
}
.sm-button.sm-button_icon:after {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  content: '';
  -webkit-transition: 0.6s ease transform, 0.4s ease opacity;
  transition: 0.6s ease transform, 0.4s ease opacity;
  -webkit-transform: translate(-50%, -50%) scale(0.3);
          transform: translate(-50%, -50%) scale(0.3);
  opacity: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
}
.sm-button.sm-button_icon .material-icons {
  color: #687189;
}
.sm-button.sm-button_icon:focus:after,
.sm-button.sm-button_icon:active:after {
  -webkit-transform: translate(-50%, -50%) scale(0.8);
          transform: translate(-50%, -50%) scale(0.8);
  opacity: 0.8;
}
.sm-button.sm-button_icon:hover:after {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 0.3;
}
.sm-button.sm-button_icon:focus,
.sm-button.sm-button_icon:hover,
.sm-button.sm-button_icon:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.sm-button.sm-button_icon:focus .material-icons,
.sm-button.sm-button_icon:hover .material-icons,
.sm-button.sm-button_icon:active .material-icons {
  color: #000000;
}
.sm-button.sm-button_start {
  background-color: rgba(0, 150, 136, 0.06);
}
.sm-button.sm-button_start:focus,
.sm-button.sm-button_start:hover,
.sm-button.sm-button_start:active {
  background-color: rgba(0, 150, 136, 0.26);
}
.sm-button.sm-button_remove {
  background-color: rgba(255, 223, 238, 0.32);
}
.sm-button.sm-button_remove:focus,
.sm-button.sm-button_remove:hover,
.sm-button.sm-button_remove:active {
  background-color: rgba(255, 223, 238, 0.52);
}
.copy-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  padding-right: 32px;
  line-height: 18px;
  color: #44414d;
  border: none;
  border-radius: 0;
  background: transparent;
  text-decoration: underline;
  color: #2196f3;
  position: relative;
}
.copy-button:after {
  content: 'filter_none';
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.3s ease right, 0.3s ease opacity;
  transition: 0.3s ease right, 0.3s ease opacity;
  text-decoration: none;
  color: #687189;
  opacity: 0;
  padding: 8px;
  font-size: 16px;
}
.copy-button:hover {
  text-decoration: underline;
}
.copy-button:hover:after {
  right: 0;
  opacity: 1;
}
.sm-widget.sm-widget_full-info > .panel-body {
  padding: 20px;
}
.sm-widget.sm-widget_full-info .panel {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.main-info {
  padding: 20px;
}
.main-info.main-info_3 .main-info__row {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.main-info.main-info_3 .main-info__column {
  -ms-flex-preferred-size: calc(33.33333333%);
      flex-basis: calc(33.33333333%);
}
.main-info.main-info_3 .main-info__column:nth-child(2) {
  padding-right: 10px;
}
.main-info__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.main-info__column {
  width: 100%;
  padding-bottom: 10px;
}
.sm-widget.sm-widget_main-info .panel-body {
  overflow-y: auto;
  padding: 40px 20px;
}
.main-info__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 20px;
}
.row-child-shown .main-info__row:last-child {
  margin-bottom: 0;
}
.main-info__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  min-width: 50%;
  padding-right: 20px;
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.main-info__item label {
  margin-bottom: 0;
  color: #9ea1a8;
  font-size: 13px;
  font-weight: 400;
}
.main-info__item > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-weight: 600;
}
.main-info__item > span .config-item-type-icon {
  margin-right: 8px;
}
.main-info__item > span em {
  font-weight: 400;
  font-style: normal;
}
.content__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  min-width: 50%;
  padding-right: 20px;
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.content__item label {
  margin-bottom: 0;
  color: #9ea1a8;
  font-size: 13px;
  font-weight: 400;
}
.content__item > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-weight: 600;
}
.content__item > span .config-item-type-icon {
  margin-right: 8px;
}
.content__item > span em {
  font-weight: 400;
  font-style: normal;
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  margin-top: 7px;
}
.select2-selection--single,
.select2-selection--single .select2-selection__rendered,
.select2-results__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.select2-selection--single .select2-selection__rendered {
  margin-right: 20px;
}
#ci-builds .build-details-container .col-sm-4 {
  min-height: 225px;
  max-height: 320px;
}
#ci-builds .build-details-container .step-description {
  overflow-y: auto;
  min-height: 175px;
  max-height: 270px;
  white-space: pre-line;
  word-wrap: break-word;
}
#ci-builds .download-form {
  width: 124px;
}
#ci-builds .label {
  width: 80px;
}
#ci-builds .test-cases {
  overflow: hidden;
  max-height: 320px;
}
#ci-builds .test-cases .dataTables_wrapper {
  overflow-y: auto;
  max-height: 294px;
}
#ci-builds .steps {
  overflow-y: hidden;
  max-height: 320px;
}
#ci-builds .steps .dataTables_wrapper {
  overflow-y: auto;
  max-height: 294px;
}
#ci-builds .steps.has-error .dataTables_wrapper {
  overflow-y: auto;
  max-height: 124px;
}
#ci-builds .steps .error-label {
  margin-top: 10px;
}
.build-details-container textarea {
  width: 100%;
  height: 130px;
  padding: 10px;
  border-color: rgba(169, 169, 169, 0.4);
}
.circle-marker {
  position: relative;
  top: 1px;
}
.circle-marker:after {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.circle-marker.color-danger:after {
  background-color: #F44336;
}
.circle-marker.color-success:after {
  background-color: #4CAF50;
}
.caption-overflow a {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}
.caption-overflow span {
  display: inline-block;
  width: auto;
  margin-left: -17px;
}
.tab-content__screenshots .thumb {
  overflow: hidden;
}
.tab-content__description {
  overflow: auto;
  max-height: 270px;
  white-space: pre-line;
}
.sm-modal_small.sm-modal .mfp-container .mfp-content {
  width: 460px;
  max-width: 460px;
}
@media (min-width: 1340px) {
  .sm-modal_small.sm-modal .mfp-container .mfp-content {
    width: 460px;
    max-width: 460px;
  }
}
.sm-modal_small .sm-modal__content {
  min-height: 160px;
  padding: 32px;
}
.sm-modal_rule-variables.sm-modal .mfp-container .mfp-content {
  width: 640px;
  max-width: 640px;
}
@media (max-width: 1340px) {
  .sm-modal_rule-variables.sm-modal .mfp-container .mfp-content {
    width: 640px;
    max-width: 640px;
  }
}
.sm-modal_transfer.sm-modal .mfp-container .mfp-content {
  width: 600px;
  max-width: 600px;
}
@media (min-width: 1340px) {
  .sm-modal_transfer.sm-modal .mfp-container .mfp-content {
    width: 600px;
    max-width: 600px;
  }
}
.sm-modal_transfer:not(.sm-modal_transfer_big) .sm-modal__content {
  height: 320px;
  min-height: 320px;
  max-height: 320px;
}
.sm-modal_transfer:not(.sm-modal_transfer_big) .sm-modal__content > div,
.sm-modal_transfer:not(.sm-modal_transfer_big) .sm-modal__content .dataTables_wrapper {
  height: 320px;
  min-height: 320px;
  max-height: 320px;
}
.sm-modal_transfer:not(.sm-modal_transfer_big) .sm-modal__content .datatable-scroll {
  overflow-x: auto;
  height: calc(215px);
  min-height: calc(215px);
  max-height: calc(215px);
}
.sm-modal_transfer.sm-modal_transfer_big .sm-modal__content {
  overflow-y: auto;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  max-height: 640px;
}
.sm-modal_transfer.sm-modal_transfer_big .sm-modal__content > div,
.sm-modal_transfer.sm-modal_transfer_big .sm-modal__content .dataTables_wrapper {
  min-height: 552px;
  max-height: 640px;
}
.sm-modal_transfer.sm-modal_transfer_big .sm-modal__content .datatable-scroll {
  overflow-x: auto;
  min-height: calc(447px);
  max-height: calc(535px);
}
.sm-modal_transfer .sm-modal__footer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
.sm-modal__content_priority.sm-modal__content {
  overflow-y: auto;
  height: 560px;
  min-height: 560px;
  max-height: 560px;
}
.sm-modal__content_priority h3 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}
.sm-modal__content_priority .input-container {
  width: 100%;
  max-width: 520px;
  margin-bottom: 30px;
  padding-right: 0;
  padding-left: 0;
}
.sm-modal__content_priority .radio-button-group {
  margin-top: 20px;
  margin-bottom: 20px;
}
.sm-modal__content_priority .radio-button {
  width: 100%;
  margin-top: 12px;
}
.sm-modal_sources .hide-disabled + .show,
.sm-modal_scom .hide-disabled + .show {
  min-height: 666px;
  text-align: center;
}
.sm-modal_sources .hide-disabled + .show .preloader,
.sm-modal_scom .hide-disabled + .show .preloader {
  position: absolute;
  top: 50%;
  margin: auto;
}
.sm-modal_sources .dataTable tr td,
.sm-modal_scom .dataTable tr td {
  overflow-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.sm-modal_sources .datatable-header__control span.select2,
.sm-modal_scom .datatable-header__control span.select2 {
  width: auto !important;
}
.sm-modal_sources .datatable-header__control span.select2 .select2-selection.select2-selection--single .select2-selection__rendered,
.sm-modal_scom .datatable-header__control span.select2 .select2-selection.select2-selection--single .select2-selection__rendered {
  padding-right: 0;
}
.sm-modal_sources select[disabled] + .select2,
.sm-modal_scom select[disabled] + .select2 {
  cursor: not-allowed;
  pointer-events: none;
}
.sm-modal_sources .select2-selection--multiple,
.sm-modal_scom .select2-selection--multiple {
  padding-right: 0 !important;
  padding-left: 0 !important;
  cursor: pointer;
}
.sm-modal_sources .select2-selection--multiple .select2-selection__rendered,
.sm-modal_scom .select2-selection--multiple .select2-selection__rendered {
  height: 100%;
  padding-right: 18px;
  padding-bottom: 0;
  padding-left: 18px;
}
.sm-modal_sources .select2-selection--multiple .select2-search,
.sm-modal_scom .select2-selection--multiple .select2-search,
.sm-modal_sources .select2-selection--multiple .select2-search__field,
.sm-modal_scom .select2-selection--multiple .select2-search__field {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.sm-modal_sources .select2-selection--multiple .select2-search__field,
.sm-modal_scom .select2-selection--multiple .select2-search__field {
  min-width: 100px;
  padding-right: 20px;
  cursor: pointer;
}
.sm-modal_sources .show-count .select2-search,
.sm-modal_scom .show-count .select2-search {
  display: none;
}
.sm-modal_sources .show-count .select2-selection__choice,
.sm-modal_scom .show-count .select2-selection__choice {
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding-top: 0;
  padding-right: 44px;
  padding-bottom: 0;
  cursor: pointer;
  white-space: nowrap;
  background: transparent;
  line-height: 42px;
}
.sm-modal_sources .select2-container--open .select2-dropdown,
.sm-modal_scom .select2-container--open .select2-dropdown {
  margin-top: 3px;
  margin-left: -3px;
}
.sm-modal.sm-modal_sources .sm-modal__content .datatable-scroll {
  overflow-y: auto;
  height: 512px;
  min-height: 512px;
  max-height: 512px;
}
.sm-modal.sm-modal_sources .row-child-shown .show {
  padding: 20px;
}
.sm-modal.sm-modal_scom .sm-modal__content .select2-container--open .select2-dropdown {
  min-width: unset;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  color: #999999;
}
.material-icons_copy-clipboard {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #687189;
}
.ci-name {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-overflow: ellipsis;
}
.ci-name .config-item-type-icon {
  margin-right: 8px;
}
.ci-name .ci-name__text {
  word-break: break-word;
}
.ci-name.ci-name_triggers .config-item-text {
  overflow: hidden;
  padding-right: 12px;
  text-overflow: ellipsis;
}
.ci-link {
  margin-right: 8px;
}
.executed-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.executed-actions__item + .executed-actions__item {
  margin-left: 12px;
}
.action-by-rules__action-title {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 500;
}
.action-by-rules:not(:only-child) {
  padding-bottom: 2px;
  padding-left: 8px;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}
.action-by-rules:not(:first-child) {
  margin-top: 4px;
}
.action-by-rules__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  margin-bottom: 4px;
  opacity: 0.5;
  font-weight: 500;
}
.action-by-rules__title > span {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}
.action-by-rules__title > span:last-child {
  padding-left: 20px;
}
.action-by-rules__item-info {
  margin-top: 20px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 12px;
}
.action-by-rules__item-info strong {
  word-break: break-all;
  word-break: break-word;
  font-weight: 600;
}
.action-by-rules__item-info em {
  margin-right: 4px;
  font-style: normal;
}
.action-by-rules__item-info span {
  word-break: break-all;
  word-break: break-word;
}
.action-by-rules__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.action-by-rules__item > span {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  word-break: break-word;
  line-height: 1;
}
.action-by-rules__item > span:first-child {
  position: relative;
  padding-right: 32px;
}
.action-by-rules__item > span:first-child:after {
  position: absolute;
  top: 50%;
  right: -8px;
  margin-top: 2px;
  content: 'arrow_forward';
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: rgba(51, 51, 51, 0.5);
  font-size: 15px;
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.action-by-rules__item > span:last-child {
  padding-left: 20px;
}
.select-container.select-container_tags .multiselect__tag {
  padding: 0;
}
.select-container.select-container_tags .result-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-right: 60px;
}
.select-container.select-container_tags .result-wrapper .tag {
  float: none;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin: 0;
  margin-top: 1px;
  padding: 0;
  border-radius: 50%;
}
.select-container.select-container_tags .multiselect__option:after {
  content: none;
}
.select-container.select-container_tags .tag {
  overflow: hidden;
  max-width: 100%;
  margin-top: 0;
  margin-right: 0;
  padding-right: 26px;
  text-overflow: ellipsis;
}
.select-container.select-container_tags .title {
  width: 100%;
  padding-left: 12px;
}
.select-container.select-container_tags .actions {
  position: absolute;
  top: 50%;
  right: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.select-container.select-container_tags .star {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  color: #fab600;
}
.select-container.select-container_tags .star:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  /* Preferred icon size */
  font-weight: 400;
  font-style: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.select-container.select-container_tags .star.selected:before {
  content: 'star';
}
.select-container.select-container_tags .star.unselected:before {
  content: 'star_border';
}
.select-container.select-container_tags .refCount {
  width: 30px;
  text-align: right;
}
.select-container.select-container_tags .bg-pink-imp {
  background-color: #E91E63 !important;
}
.select-container.select-container_tags .bg-violet-imp {
  background-color: #9C27B0 !important;
}
.select-container.select-container_tags .bg-indigo-imp {
  background-color: #3F51B5 !important;
}
.select-container.select-container_tags .bg-blue-imp {
  background-color: #03A9F4 !important;
}
.select-container.select-container_tags .bg-teal-imp {
  background-color: #009688 !important;
}
.select-container.select-container_tags .bg-green-imp {
  background-color: #8BC34A !important;
}
.select-container.select-container_tags .bg-orange-imp {
  background-color: #FF9800 !important;
}
.select-container.select-container_tags .bg-brown-imp {
  background-color: #795548 !important;
}
.select-container.select-container_tags .bg-slate-imp {
  background-color: #607D8B !important;
}
.select-container.select-container_tags .bg-transparent-imp {
  color: #000000;
  background-color: transparent !important;
  -webkit-box-shadow: inset 0 0 1px 1px #ececec;
          box-shadow: inset 0 0 1px 1px #ececec;
}
.b24-widget-button-wrapper {
  display: none !important;
}
.bx-livechat-position-bottom-right {
  top: 130px !important;
  bottom: auto !important;
}
.bx-livechat-show {
  -webkit-transform-origin: center top !important;
          transform-origin: center top !important;
}
.bitrix-chat-link__icon {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}
.status-by-time .legend-square,
.dispatcher-widget .legend-square {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 16px;
  height: 12px;
  margin-right: 5px;
  margin-left: 20px;
}
.status-by-time .legend-title,
.dispatcher-widget .legend-title {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
}
.item-id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 4px 6px;
  color: #687189;
  border-radius: 3px;
  background-color: #e7e9ef;
  font-size: 10px;
  font-weight: 300;
  margin-left: 4px;
}
.item-id.item-id--static {
  font-size: 12px;
  font-weight: 500;
  color: #1b1b1c;
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 1px #e7e9ef;
          box-shadow: 0px 0px 0px 1px #e7e9ef;
}
.item-id .item-id__number {
  font-weight: 500;
}
a.item-id {
  cursor: pointer;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease;
}
a.item-id:hover,
a.item-id:focus,
a.item-id:active {
  color: #ffffff;
  background-color: #687189;
}
.con-list__actions a.item-id {
  margin-left: 0;
}
